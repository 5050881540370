import { Chart_Bin_Type, FilterInput, Insight_Collection_Type, useGetInsightPreviewChartToDeleteQuery } from '../../../../generated/graphql';
import InsightChart from './InsightChart';

interface InsightPreviewChartProps {
  teamId: number;
  collectionId: number;
  collectionType: Insight_Collection_Type;
  filterInput: FilterInput;
  chartBin: Chart_Bin_Type;
}

/**
 * This fetches relevant chart data given a collection type & id, teamId, and filterInput
 * @param props
 * @returns
 */
const InsightPreviewChart = (props: InsightPreviewChartProps) => {
  const { filterInput, chartBin } = props;
  const { loading, data } = useGetInsightPreviewChartToDeleteQuery({
    variables: {
      teamId: props.teamId,
      collectionId: props.collectionId,
      collectionType: props.collectionType,
      filterInput: filterInput,
      chartBin: chartBin,
    },
  });

  return <InsightChart seriesData={data?.getInsightPreviewChartToDelete} filterInput={filterInput} loading={loading} />;
};

export default InsightPreviewChart;
