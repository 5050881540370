import React, { useContext } from 'react';
import { AssistantDataContext, AssistantDispatchContext } from '../../../context/assistantContext';
import { AssistantActions } from '../../../reducers/assistant/AssistantDispatch';
import { ErrorIcon } from 'react-hot-toast';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import {
  SuggestedQuestionsActions,
  SuggestedQuestionsStatus,
} from '../../../reducers/assistant/SuggestedQuestionsDispatch';
import {
  SuggestedQuestionsDataContext,
  SuggestedQuestionsDispatchContext,
} from '../../../context/suggestedQuestionsContext';
import { Events, logEventWithContext } from '../../../v2/AnalyticsUtil';
import { unique } from 'underscore';

function SuggestedQuestion(props: { question: string }) {
  const dispatch = useContext(AssistantDispatchContext);

  return <div
    onClick={async () => {
      dispatch({ type: AssistantActions.SUBMIT_QUESTION, payload: { question: props.question } });
      dispatch({ type: AssistantActions.UPDATE_USER_INPUT, payload: { userInput: props.question } });
      logEventWithContext(Events.QueryAssistant, {});
    }}
    className={'animate-fade-in py-1 px-3 mx-2 inline-flex items-center rounded-3xl border-2 border-silver text-center text-sm outline-none text-blueberry hover:cursor-pointer hover:bg-blueberry-lighter hover:text-milk'}>
    {props.question}
  </div>;
}

const SuggestedQuestionLoading = () => <div
  data-testid={'suggested-questions-loading'}
  className={'py-1 px-3 mx-2 inline-flex justify-center rounded-3xl border-2 text-center outline-none bg-gray-300 w-44 h-8 animate-pulse'}>
</div>

export const SuggestedQuestions = () => {
  const dispatch = useContext(SuggestedQuestionsDispatchContext);
  const {questions, status, load, page } = useContext(SuggestedQuestionsDataContext);

  switch (status) {
    case SuggestedQuestionsStatus.done:
      return (
        <div className="flex flex-row w-3/4 my-4">
          <div className={'py-1 px-3 text-blueberry text-sm'}>Suggested Questions:</div>
          {unique(questions).slice(0,3).map((question) => <SuggestedQuestion question={question} />)}
          <div
            onClick={async () => {
              dispatch({ type: SuggestedQuestionsActions.LOAD_SUGGESTED_QUESTIONS, payload: { page: page + 1}});
            }}
            className={'animate-fade-in py-1 px-3 mx-2 inline-flex items-center rounded-3xl border-2 border-silver text-center text-sm outline-none text-blueberry hover:cursor-pointer hover:bg-blueberry-lighter hover:text-milk'}>
            <ArrowPathIcon className={`h-4 w-4 stroke-2 ${load && 'animate-spin'}`}/>
          </div>

        </div>
      );
    case SuggestedQuestionsStatus.loading:
      return <div className="flex flex-row w-3/4 my-4">
        <div className={'py-1 px-3 text-blueberry text-sm'}>Suggested Questions:</div>
        <SuggestedQuestionLoading />
        <SuggestedQuestionLoading />
        <SuggestedQuestionLoading />
      </div>;
    case SuggestedQuestionsStatus.failed:
      return <div className="flex flex-row w-3/4 my-4">
        <div className={"flex text-error"}>
          <ErrorIcon className={'mx-2'}/>
          <p className={'text-error text-red-700'}>Unable to generate suggested questions at this time.</p>
        </div>
      </div>
  }
}