//@ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth } from 'aws-amplify';
import { ApolloProvider } from '@apollo/client';
import { UserContextProvider } from './v2/contexts/UserContext';
import { AppContextProvider } from './v2/contexts/AppContext';
import mixpanel from 'mixpanel-browser';
import { initTelemetry } from './applicationTelemetry';
import { trackUserActions } from './latencyTracker';
import { configureAmplifyAuth, STANDARD_ISSUER } from './AuthConfigs';
// tw-elements is actually used don't remove it
import 'tw-elements';
import { isDevMode } from './v2/AnalyticsUtil';
import { client } from './client';
import { UnauthorizedModal } from './v3/components/Modals/AuthorizationModal';

//The ResizeObserver loop limit exceeded warning is a known, non-breaking warning that is thrown only on local and might depend on your browser's extensions.
//There is no clear solution and it's widely suggested to safely ignore it, as it doesn't affect production users.
const suppressedWarnings = /(ResizeObserver loop limit exceeded|ResizeObserver loop completed with undelivered notifications)/;
const originalWarn = console.warn;
console.warn = (message, ...args) => {
  if (suppressedWarnings.test(message)) {
    return;
  }
  originalWarn.call(console, message, ...args);
};

initTelemetry();
const recordSession = async () => {
  let user;

  // do not try to get the current session before completing the auth flow, otherwise it will try to get the session from
  // the default issuer (google) rather than the one that gets configured when the SSOSession component renders
  if(window.location.pathname.includes('/auth/session/')) return;
  try {
    user = (await Auth.currentSession())?.getIdToken()?.payload;
  } catch {
    return;
  }
  const insertScript = () => {
    if (typeof window.__inspld !== 'undefined') return;
    window.__inspld = 1;
    const insp = document.createElement('script');
    insp.type = 'text/javascript';
    insp.async = true;
    insp.id = 'inspsync';
    insp.src =
      ('https:' === document.location.protocol ? 'https' : 'http') +
      '://cdn.inspectlet.com/inspectlet.js?wid=923047165&r=' +
      Math.floor(new Date().getTime() / 3600000);
    const x = document.getElementsByTagName('script')[0];
    if (x.parentNode) {
      x.parentNode.insertBefore(insp, x);
    }
  };
  if (!user || !user.email.includes('@unwrap.ai')) {
    const insp = document.getElementById('inspsync');
    if (!insp) {
      // when removing trackable orgs, add a flag here to check for currentOrg.isTrackable
      // for right now, we're only tracking non-Unwrap users.
      window.__insp = window.__insp || [];
      window.__insp.push(['wid', 923047165]);
    }

    setTimeout(insertScript, 0);
  } else {
    // remove script if above conditions do not apply
    const insp = document.getElementById('inspsync');
    if (insp) {
      insp.remove();
    }
  }
};

if(!window.location.pathname.includes('/auth/session/')) {
  configureAmplifyAuth(STANDARD_ISSUER);
}

trackUserActions();

if (!isDevMode) {
  // MIXPANEL
  mixpanel.init('dd52785f537781c3f5b474cd7c0a79b1', {
    debug: false,
    api_host: 'https://tracking.api.production.unwrap.ai',
    api_payload_format: 'json',
    verbose: false,
    /** Turning off request batching because it looks like this module is sending some partial payloads to our api which is causing issues. */
    batch_requests: false,
  });
  recordSession();
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AppContextProvider>
        <UserContextProvider>
          <App />
          <UnauthorizedModal />
        </UserContextProvider>
      </AppContextProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
