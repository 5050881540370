import { useEffect, useState } from 'react';
import { ArrayElement } from '../../utilities';
import {
  Breakdown,
  Chart,
  ChartFragment,
  Chart_Type,
  GetCustomChartQuery,
  GetCustomChartQueryResult,
  GetCustomChartsQuery,
  SeriesData,
  Y_Axis_Data,
} from '../../generated/graphql';
import ChartView from '../baseComponents/ChartView';
import { ChartType } from '../../v2/hooks/ChartHook';
import { ChartData, ChartOptions, ChartTypeRegistry, CoreScaleOptions, LegendItem, Scale, Tick, TooltipItem } from 'chart.js';
import { truncateAndEllipsis } from '../../v2/util';
import toast from 'react-hot-toast';

const isHorizontalChart = (chartType: Chart_Type) => {
  return chartType === Chart_Type.HorizontalBar;
};
const isPercentageChart = (yAxisData: Y_Axis_Data) => yAxisData === Y_Axis_Data.Favorability || yAxisData === Y_Axis_Data.RelativeShare;
const colors = [
  '#E06D87',
  '#414880',
  '#FEC6A3',
  '#C29ADD',
  '#F49EC4',
  '#D2D3FF',
  '#887AB9',
  '#C0BCD0',
  '#FCE7B4',
  '#F8A691',
  '#34495E',
  '#F5B7B1',
  '#7D3C98',
  '#F8C471',
  '#F0B27A',
  '#1ABC9C',
  '#E67E22',
  '#5D6D7E',
  '#AF7AC5',
];

const sentimentColorMap: Record<string, string> = {
  Negative: '#E06D87',
  Neutral: '#F5DB9C',
  Positive: '#4BACB2',
};

const npsBucketColorMap: Record<string, string> = {
  detractor: '#E06D87',
  passive: '#F5DB9C',
  promoter: '#4BACB2',
};

const isCategoricalChart = (chartType: Chart_Type) => chartType === Chart_Type.Pie || chartType === Chart_Type.HorizontalBar;
const isStackedChart = (chartType: Chart_Type) => chartType === Chart_Type.StackedBar || chartType === Chart_Type.StackedArea;

const generateDataset = (
  data: SeriesData,
  type: 'normalized' | 'aggregate',
  overallChartData: ArrayElement<GetCustomChartsQuery['getCharts']>,
  index: number,
  fill?: boolean,
  differentColorPerBucket?: boolean
) => {
  const dataToMap = type === 'normalized' ? data.normalizedData : data.aggregateData;
  return dataToMap?.slice(0, 10).map((curData, idx) => {
    let labelName: string | undefined = data.breakdownLabels?.[idx]?.name;
    const npsNames = ['detractor', 'passive', 'promoter'];
    const isNpsBucket =
      data.breakdownLabels?.length === 3 &&
      data.breakdownLabels
        ?.map((obj) => obj.name)
        .sort()
        .every((name, idx) => name.toLowerCase() === npsNames[idx]);
    let color: string = colors[idx + index];
    if (labelName) {
      if (data.breakdown === 'sentiment') color = sentimentColorMap[labelName];
      else if (data.breakdown === 'segment' && isNpsBucket) color = npsBucketColorMap[labelName.toLowerCase()];
    }
    let colorBucket;
    if (differentColorPerBucket) {
      if (data.breakdown === Breakdown.Sentiment) {
        colorBucket = data.chartLabels.map((label) => sentimentColorMap[label]) || [];
      } else if (data.breakdown === Breakdown.Segment && isNpsBucket) {
        colorBucket = data.chartLabels.map((label) => npsBucketColorMap[label.toLowerCase()]) || [];
      } else if (differentColorPerBucket) {
        colorBucket = colors;
      } else {
        colorBucket = [color];
      }
    }

    return {
      label: labelName ?? '',
      data: curData,
      backgroundColor: differentColorPerBucket ? colorBucket : color,
      borderColor: differentColorPerBucket ? colorBucket : color,
      fill,
    };
  });
};

const getChartTypeRegistry = (type: Chart_Type) => {
  if (type === Chart_Type.StackedBar) return 'bar' as keyof ChartTypeRegistry;
  if (type === Chart_Type.VerticalBar) return 'bar' as keyof ChartTypeRegistry;
  if (type === Chart_Type.Line) return 'line' as keyof ChartTypeRegistry;
  if (type === Chart_Type.HorizontalBar) return 'bar' as keyof ChartTypeRegistry;
  if (type === Chart_Type.Pie) return 'doughnut' as keyof ChartTypeRegistry;
  return 'line';
};

export const CustomChart = ({ customChartData, showLegend = true }: { customChartData: ChartFragment; showLegend?: boolean }) => {
  const [chartData, setChartData] = useState<any>();
  const [isChartEmpty, setIsChartEmpty] = useState<boolean>(false);
  const [randKey, setRandKey] = useState<number>(Math.random());
  useEffect(() => {
    setRandKey(Math.random());
  }, [customChartData.type]);

  useEffect(() => {
    if (customChartData.seriesData && customChartData.seriesData.length > 0) {
      let labels: string[] = customChartData.seriesData[0].chartLabels; //Labels should be the same for all. Maybe we can add a check?
      let datasets: any[] = [];
      customChartData.seriesData?.map((data, i) => {
        //Absolute Chart -> Aggregate Data ||| Percentage Chart -> Normalized Data
        if (isPercentageChart(customChartData.y_axis_data!))
          datasets.push(
            ...generateDataset(
              data as SeriesData,
              'normalized',
              customChartData as Chart,
              i,
              customChartData.type === Chart_Type.StackedArea,
              customChartData.type === Chart_Type.Pie
            )
          );
        else
          datasets.push(
            ...generateDataset(
              data as SeriesData,
              'aggregate',
              customChartData as Chart,
              i,
              customChartData.type === Chart_Type.StackedArea,
              customChartData.type === Chart_Type.Pie
            )
          );
      });
      const newChartData = { labels, datasets };
      const areAllChartValuesZero = customChartData.seriesData?.every((serData) => serData.aggregateData.every((aggData) => aggData.every((val) => val === 0)));
      setIsChartEmpty(areAllChartValuesZero);
      setChartData(newChartData);
    }
  }, [customChartData]);
  return (
    <div className="h-full">
      {isChartEmpty ? (
        <div className="flex flex-col items-center justify-center h-full px-2">
          <div className="text-center text-gray-500">No data matches the selected configurations and filters</div>
        </div>
      ) : chartData ? (
        <ChartView
          key={randKey}
          type={getChartTypeRegistry(customChartData.type)}
          data={chartData}
          options={getChartOptions(
            customChartData.y_axis_data === Y_Axis_Data.RelativeShare
              ? ChartType.CustomPercentage
              : customChartData.y_axis_data === Y_Axis_Data.Favorability
              ? ChartType.Favorability
              : ChartType.CustomAbsolute,
            customChartData.type,
            customChartData.seriesData?.[0]?.tooltipLabels ?? [],
            customChartData.seriesData?.[0]?.chartLabels ?? [],
            false,
            showLegend
          )}
        />
      ) : null}
    </div>
  );
};

/**
 * This function aims to provide an estimate of the maximum number of legends that can be displayed on a chart without overflowing.
 * @param chartWidth
 * @param labels
 * @returns
 */
const calculateMaxLegends = (chartWidth: number, labels: string[]) => {
  const width = 17; // Eyeballed width. Best heuristic (looks good) that doesn't involve doing ugly calculations with the canvas.
  let totalWidth = 0;
  let maxLegends = 0;

  for (const label of labels) {
    const labelWidth = label.length * width;
    if (totalWidth + labelWidth > chartWidth) {
      break;
    }
    totalWidth += labelWidth;
    maxLegends++;
  }

  return maxLegends;
};

/**
 * Utility function to create legend items.
 * @param chart
 * @param chartType
 * @returns
 */
const createLegendItems = (chart: any, chartType: Chart_Type): LegendItem[] => {
  const finalArr: LegendItem[] = [];

  if (chartType === Chart_Type.Pie || chartType === Chart_Type.HorizontalBar) {
    chart.data.labels.forEach((label: string, index: number) => {
      finalArr.push({
        text: label,
        fillStyle: chart.data.datasets[0].backgroundColor[index],
        strokeStyle: chart.data.datasets[0].backgroundColor[index],
        lineWidth: 2,
        datasetIndex: index,
        hidden: chart.getDatasetMeta(0).data[index].hidden,
      });
    });
  } else {
    chart.data.datasets.forEach((dataset: any, index: number) => {
      const meta = chart.getDatasetMeta(index);
      const label = dataset.label.length > 20 ? `${dataset.label.substring(0, 20)}...` : dataset.label;

      finalArr.push({
        text: label,
        fillStyle: dataset.backgroundColor,
        strokeStyle: dataset.backgroundColor,
        lineWidth: 2,
        datasetIndex: index,
        hidden: !meta.visible,
      });
    });
  }

  return finalArr;
};

/**
 * Generate the final array of legends with the "X more..." item if necessary.
 * @param chart
 * @param chartType
 * @returns
 */
const generateTrimmedLegendItems = (chart: any, chartType: Chart_Type): LegendItem[] => {
  const legendItems = createLegendItems(chart, chartType);
  const labels = legendItems.map((item) => item.text);
  const maxLegends = calculateMaxLegends(chart.width, labels);

  let finalArr = legendItems.slice(0, maxLegends);

  if (legendItems.length > maxLegends) {
    finalArr.push({
      text: `${legendItems.length - maxLegends} more...`,
      lineWidth: 2,
      datasetIndex: -1,
      hidden: false,
    });
  }

  return finalArr;
};

// Utility function to get chart data based on chart type
const getChartData = (chart: any, chartType: Chart_Type): ChartData[] => {
  if (chartType === Chart_Type.Pie) {
    return chart.data.labels ?? [];
  } else {
    return chart.data.datasets ?? [];
  }
};

const getChartOptions = (
  chartType: ChartType,
  chart_type: Chart_Type,
  fullLabels: string[],
  chartLabels: string[],
  disableAnnotations?: boolean,
  showLegend: boolean = true
): ChartOptions => {
  //Uhh..we got a naming issue, ChartType vs Chart_Type. One represents if it's absolute, relative, etc. The other represents the kind of chart.
  //We need to think about better names.

  return {
    animations: {
      numbers: { duration: 500 },
    },
    layout: {
      padding: {
        top: chart_type === Chart_Type.Pie ? 10 : 0,
        bottom: 0,
      },
    },
    indexAxis: chart_type === Chart_Type.HorizontalBar ? 'y' : 'x',

    //@ts-ignore
    lineTension: 0.3,
    interaction: {
      mode: 'nearest',
      axis: 'xy',

      intersect: false,
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      annotation: {
        interaction: {
          intersect: false,
        },
      },
      legend: {
        onClick: (e, legendItem, legend) => {
          if (isCategoricalChart(chart_type)) return;
          const chart = legend.chart;
          const datasetIndex = legendItem.datasetIndex!;
          chart.getDatasetMeta(datasetIndex).hidden = !chart.getDatasetMeta(datasetIndex).hidden;
          chart.update();
        },
        onHover: (event, legendItem, legend) => {
          if (legendItem.datasetIndex === -1) {
            const chart = legend.chart;
            const data = getChartData(chart, chart_type);
            const labels = data.map((item: any) => item.label ?? '');
            const maxAmountOfLegends = calculateMaxLegends(legend.chart.width, labels);
            const remainingLegends: LegendItem[] = data.slice(maxAmountOfLegends).map((dataset: any, index: number) => {
              return {
                text: dataset.label,
                fillStyle: dataset.backgroundColor,
                strokeStyle: dataset.backgroundColor,
                lineWidth: 2,
                datasetIndex: index + maxAmountOfLegends,
                hidden: false,
              };
            });
            showRemainingLegendsPopover(event.native, remainingLegends);
          }
        },
        onLeave: () => {
          hideRemainingLegendsPopover();
        },
        display: showLegend,
        position: 'bottom',
        labels: {
          color: '#292E5B',
          font: {
            size: 13,
            family: 'SofiaPro',
          },
          boxWidth: 10,
          boxHeight: 10,
          generateLabels: function (chart: any) {
            return generateTrimmedLegendItems(chart, chart_type);
          },
        },
      },
      tooltip: {
        // control tooltip visibility if all values are zero
        filter: function (tooltipItem) {
          const dataset = tooltipItem.chart.data.datasets[tooltipItem.datasetIndex];
          const data = dataset.data[tooltipItem.dataIndex];
          if (!data) {
            return false;
          }
          // If all values are zero, return false to hide tooltip.
          for (let i = 0; i < Number(data.valueOf()); i++) {
            if (data !== 0) {
              return true;
            }
          }
          return false;
        },
        backgroundColor: '#FFF',
        bodyColor: '#292E5B',
        titleColor: '#292E5B',
        footerColor: '#292E5B',
        mode: 'index',
        position: 'custom',
        intersect: false,
        enabled: true,
        titleFont: {
          family: 'SofiaPro',
          size: 10,
        },
        titleMarginBottom: 1,
        bodyFont: {
          family: 'SofiaPro',
          size: 12,
        },
        padding: 10,
        footerFont: {
          size: 10,
          family: 'SofiaPro',
          style: 'italic',
          weight: 'normal',
        },
        borderWidth: 1,
        borderColor: '#292E5B',
        footerMarginTop: 1,
        // sort visible tooltip items in descending order
        itemSort(a, b, data) {
          return Number(b.formattedValue) - Number(a.formattedValue);
        },
        callbacks:
          chartType === ChartType.CustomAbsolute || chartType === ChartType.CustomPercentage || ChartType.Favorability
            ? {
                title: (tooltipItem: TooltipItem<'bar' | 'line'>[]) => {
                  if (tooltipItem.length > 0) {
                    return fullLabels[tooltipItem[0].dataIndex];
                  }
                  return '';
                },
                label: function (data: TooltipItem<'bar' | 'line'>) {
                  const label = chart_type === Chart_Type.Pie ? data.label : data.dataset.label;
                  // do not show tooltip row if value is zero
                  if (Number(data.formattedValue) === 0) {
                    return '';
                  }
                  if (chartType === ChartType.CustomPercentage) {
                    let floor = Math.round(parseFloat(data.formattedValue.replace(/,/, '.')) * 10) / 10;
                    if (floor === 0) {
                      floor = Number(data.formattedValue);
                    }
                    return floor + '% of feedback';
                  }
                  if (chartType === ChartType.Favorability) {
                    let floor = Math.round(parseFloat(data.formattedValue.replace(/,/, '.')) * 10) / 10;
                    if (floor === 0) {
                      floor = Number(data.formattedValue);
                    }
                    return floor + '% positive sentiment rate';
                  }

                  if (chartType === ChartType.CustomAbsolute) {
                    let finalVal = data.formattedValue + ' mentions';
                    return finalVal;
                  }
                  return data.formattedValue;
                },
              }
            : {},
      },
    },
    elements: {
      point: {
        radius: 3,
      },
      bar: {
        borderRadius: 4,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: chart_type === Chart_Type.StackedBar,
        display: chart_type === Chart_Type.Pie ? false : true,
        grid: {
          display: false,
        },
        ticks: {
          padding: 7,
          display: true,
          maxRotation: 0,
          color: '#6B7280',
          font: {
            size: 12,
            family: 'SofiaPro',
          },
          callback: function (value: string | number, index: number, values: Tick[]) {
            // we do this because the 'x' axis for horizontal bar charts is actually the 'y' axis in the data
            // so we need to display the value from the data array instead of the labels array
            // for other chart types we just pass the chart labels
            if (isHorizontalChart(chart_type)) {
              if (chartType === ChartType.CustomPercentage || chartType === ChartType.Favorability) {
                return `${value}%`;
              } else {
                return value.toLocaleString();
              }
            } else {
              return chartLabels[index];
            }
          },
        },
      },
      ...(chart_type === Chart_Type.HorizontalBar && {
        y: {
          display: true,
          grid: {
            display: false,
          },
          ticks: {
            display: true,
            color: '#6B7280',
            font: {
              size: 12,
              family: 'SofiaPro',
            },
          },
        },
      }),
      ...(chartType === ChartType.CustomAbsolute &&
        !isCategoricalChart(chart_type) && {
          mentions: {
            stacked: isStackedChart(chart_type),
            position: 'left',
            grid: {
              display: true,
              color: '#f1f1f1',
              drawBorder: false,
            },
            ticks: {
              padding: 15,
              precision: 0,
              autoSkip: false,
              color: '#6B7280',
              font: {
                size: 12,
                family: 'SofiaPro',
              },
            },
            beginAtZero: true,
          },
        }),
      ...((chartType === ChartType.CustomPercentage || chartType === ChartType.Favorability) &&
        !isCategoricalChart(chart_type) && {
          percentage: {
            stacked: isStackedChart(chart_type),
            position: 'left',
            grid: {
              display: true,
              color: '#f1f1f1',
              drawBorder: false,
            },
            display: true,
            ticks: {
              padding: 15,
              autoSkip: false,
              color: '#6B7280',
              font: {
                size: 12,
                family: 'SofiaPro',
              },
              callback: function (value: string | number, index: number, values: Tick[]) {
                return value + '%';
              },
            },
            beginAtZero: true,
            afterDataLimits: (scale: Scale<CoreScaleOptions>) => {
              const datasets = scale.chart.data.datasets;

              //If there's a breakdown, get the max but with each point's values added together
              if (datasets && datasets.length >= 1 && isStackedChart(chart_type)) {
                const dataValues = datasets[0].data.map((_: any, index: number) =>
                  datasets.reduce((acc: number, cur: any) => {
                    return acc + cur.data[index];
                  }, 0)
                );
                const maxDataPoint = +Math.max(...dataValues).toFixed(1);

                scale.max = Math.max(maxDataPoint, 100);
                scale.min = 0;
              } else {
                //If no breakdown, get the max of all datasets
                const dataValues = datasets.flatMap((dataset) => dataset.data) as number[];
                const maxDataPoint = Math.max(...dataValues);
                const minDataPoint = Math.min(...dataValues);

                let padding;
                if (maxDataPoint < 10 && maxDataPoint - minDataPoint < 3) {
                  padding = 0.1;
                } else {
                  padding = 5;
                }

                scale.max = Math.min(maxDataPoint + padding, 100);
                scale.min = 0;
              }
            },
          },
        }),
    },
  };
};

/**
 * Function to show the popover with the remaining legends.
 * Creates a popover on the HTML body with the id 'more-legends-popover', and shows the remaining legends.
 * Each Chart must have their popover in the corresponding position. To simply logic we delete and create for each chart.
 */
const showRemainingLegendsPopover = (event: any, remainingLegends: LegendItem[]) => {
  if (!event) return;

  const existingPopover = document.getElementById('more-legends-popover');
  if (existingPopover) existingPopover.remove();

  // Create a new popover element
  const popover = document.createElement('div');
  popover.id = 'more-legends-popover';
  popover.style.position = 'absolute';
  popover.style.pointerEvents = 'none';
  popover.style.transition = 'opacity 0.2s ease-in-out';

  popover.className =
    'opacity-0 z-[999999] invisible font-sofiapro text-sm text-blueberry bg-white flex flex-wrap max-w-[32rem] gap-x-3 border-2 border-gray-200 py-1 px-5 rounded-md shadow-lg'; // Tailwind classes

  // Populate the popover content
  const content = remainingLegends
    .map(
      (legend) => `
        <div class="flex flex-row items-start gap-x-2">
          <span class="flex-shrink-0 w-2 h-2 mt-1.5" style="background-color:${legend.fillStyle}"></span>
          <p>${legend.text}</p>
        </div>`
    )
    .join('');

  popover.innerHTML = content;
  document.body.appendChild(popover);

  // Calculate the width of the popover
  const popoverWidth = popover.offsetWidth;

  // Position the popover near the mouse position
  popover.style.left = `${event.x + window.scrollX - popoverWidth}px`;
  popover.style.top = `${event.y + window.scrollY}px`;

  // Ensure smooth fade-in by delaying the class change
  requestAnimationFrame(() => {
    popover.classList.remove('opacity-0', 'invisible');
    requestAnimationFrame(() => {
      popover.classList.add('opacity-100', 'visible');
    });
  });
};

/**
 * Function to hide the popover with the remaining legends
 */
const hideRemainingLegendsPopover = () => {
  const popover = document.getElementById('more-legends-popover');
  if (!popover) return;

  // Hide and then remove the popover after the transition
  popover.classList.remove('opacity-100', 'visible');
  popover.classList.add('opacity-0');

  setTimeout(() => {
    popover.remove();
  }, 200); // Match the timeout to the CSS transition duration (200ms here)
};
