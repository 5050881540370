import { Chart } from 'react-chartjs-2';
import { ChartAnnotationOptions } from '../../v2/hooks/ChartHook';
import { ChartOptions, ChartData, ChartTypeRegistry, Filler } from 'chart.js';
import { memo, useEffect } from 'react';
import { isEqual } from 'underscore';

const ChartView = ({ data, options, type }: { data: ChartData; options: ChartOptions; type?: keyof ChartTypeRegistry }) => {

  return <Chart type={type ?? 'line'} data={data} options={options} plugins={[Filler, LegendBackgroundPlugin]} />;
};

/**
 * Returns true if:
 *  - If the number of annotations on the chart are the same
 *  - That each array of annotations have objects w/ the same annotationId, title, date, description, and value
 *  - The data has not changed
 * @param prevProps
 * @param nextProps
 * @returns bool
 */
const arePropsEqual = (prevProps: Readonly<{ data: ChartData; options: ChartOptions }>, nextProps: Readonly<{ data: ChartData; options: ChartOptions }>) => {
  const areAnnotationsEqual = () => {
    const prevAnnotations = Object.values(prevProps.options.plugins?.annotation?.annotations ?? {});
    const nextAnnotations = Object.values(nextProps.options.plugins?.annotation?.annotations ?? {});
    /**
     * Returns a copy of an annotation containing only the properties to be checked for equality
     * @param annotation
     * @returns
     */
    const comparableAnnotation = (annotation: Partial<ChartAnnotationOptions>) => {
      const updatedAnnotation = {
        annotationId: annotation?.annotationId,
        date: annotation?.date,
        title: annotation?.title,
        description: annotation?.description,
        value: annotation?.value,
      };
      return updatedAnnotation;
    };
    return prevAnnotations.length
      ? prevAnnotations.every((prev, i) => {
          const prevA = comparableAnnotation(prev as ChartAnnotationOptions);
          const nextA = comparableAnnotation(nextAnnotations[i] as ChartAnnotationOptions);
          return isEqual(prevA, nextA);
        }) && prevAnnotations.length === nextAnnotations.length
      : prevAnnotations.length === nextAnnotations.length;
  };
  const areDatasetsEqual = () => {
    const prevData = prevProps.data.datasets.map((data) => data.data);
    const nextData = nextProps.data.datasets.map((data) => data.data);
    return isEqual(prevData, nextData);
  };
  const areOptionsEqual = () => {
    const prevOptions = prevProps.options;
    const nextOptions = nextProps.options;
    return JSON.stringify(prevOptions) === JSON.stringify(nextOptions);
  };
  const areAllEqual = areOptionsEqual() && areAnnotationsEqual() && areDatasetsEqual();
  return areAllEqual;
};

// returns a memoized version of the chart to prevent excessive re-renders
export default memo(ChartView, arePropsEqual);

//This lets us set a background for the legends section.
const LegendBackgroundPlugin = {
  id: 'legendBackgroundPlugin',
  beforeDraw: (chart: any) => {
    const { ctx, chartArea, legend } = chart;
    if (legend && legend.position === 'bottom') {
      const legendHeight = legend.height || 0;

      const canvasHeight = chart.height;

      const radius = 11; // Adjust this value for more or less rounding

      const legendTop = canvasHeight - legendHeight - 20; // Subtract 20 to account for some padding

      // Draw background rectangle covering the legend area
      ctx.save();
      ctx.fillStyle = 'rgba(247, 247, 247, 1)'; // Customize the background color here

      // Draw rounded rectangle
      ctx.beginPath();
      ctx.moveTo(0, legendTop + 17); // Start at the top-left corner
      ctx.lineTo(chart.width, legendTop + 17); // Draw top line
      ctx.lineTo(chart.width, legendTop + 17 + legendHeight - radius + 5); // Right side down to where rounding begins
      ctx.quadraticCurveTo(chart.width, legendTop + 17 + legendHeight + 5, chart.width - radius, legendTop + 17 + legendHeight + 5); // Bottom-right corner
      ctx.lineTo(radius, legendTop + 17 + legendHeight + 5); // Bottom line
      ctx.quadraticCurveTo(0, legendTop + 17 + legendHeight + 5, 0, legendTop + 17 + legendHeight - radius + 5); // Bottom-left corner
      ctx.lineTo(0, legendTop + 17); // Left side up to the top
      ctx.closePath();
      ctx.fill(); // Fill the rounded rectangle with color
      ctx.restore();
    }
  },
};
