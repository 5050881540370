import { CheckIcon, ClockIcon, MinusCircleIcon as SolidMinusCircleIcon } from '@heroicons/react/24/solid';
import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { External_Ticket_Status, ExternalTicketFragment } from '../../../../generated/graphql';
import { ExternalTicketsAction, ExternalTicketsActionTypes } from '../../../../reducers/externalTickets/externalTicketsReducer';
import { truncateAndEllipsis, localDateString, capitalizeFirstLetter, classNames } from '../../../../v2/util';
import moment from 'moment';
import { useUnlinkExternalTicketHook } from '../../../hooks/UnlinkLinkExternalTicketHook';
import toast from 'react-hot-toast';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import Tippy from '@tippyjs/react';
import { Icon } from '@iconify/react';

export const LinkedActionsList = ({
  tickets,
  dispatch,
  groupId,
}: {
  tickets: ExternalTicketFragment[];
  dispatch: React.Dispatch<ExternalTicketsAction>;
  groupId: number;
}) => {
  const { curOrgId: orgId } = useValidTeamAppContext();
  const unlinkHook = useUnlinkExternalTicketHook({ orgId });

  const handleUnlink = async (ticket: ExternalTicketFragment) => {
    const toastId = toast('Unlinking ticket...', { duration: 30000 });
    try {
      await unlinkHook.unlinkTicketFromGroup({ groupId, ticketId: ticket.id, scraperKey: ticket.integration.type.scraperKey });
      dispatch({ type: ExternalTicketsActionTypes.REMOVE_EXTERNAL_TICKET, payload: { ticketId: ticket.id } });
      toast.success('Ticket unlinked');
    } catch (err) {
      toast.error('Error unlinking ticket');
    }
    toast.dismiss(toastId);
  };

  return (
    <div className="flex flex-col mt-1 bg-silver rounded-lg px-1  divide-y">
      {tickets.map((ticket) => (
        <LinkedActionRow ticket={ticket} unlinkTicket={handleUnlink} />
      ))}
    </div>
  );
};

//Do we wanna call them Done-Pending?...
export const LinkedActionStatusMap = {
  [External_Ticket_Status.Open]: 'Pending',
  [External_Ticket_Status.Closed]: 'Done',
};

const linkedActionStatusTooltip = (status: External_Ticket_Status, closedDate: string | null) => {
  if (status === External_Ticket_Status.Closed) {
    if (closedDate) return `Closed on ${moment.utc(new Date(closedDate)).format('ll')}`;
    return 'Closed date unavailable';
  }
  if (status === External_Ticket_Status.Open) return 'Pending';
};

const LinkedActionRow = ({ ticket, unlinkTicket }: { ticket: ExternalTicketFragment; unlinkTicket: (ticket: ExternalTicketFragment) => void }) => {
  const dueDateText = ticket.dueDate ? `Due ${moment.utc(new Date(ticket.dueDate)).format('ll')}` : 'No due date';
  return (
    <a
      className="flex flex-col xl:flex-row justify-between rounded-lg hover:bg-silver-darker cursor-pointer duration-100 px-1 py-2 gap-x-8"
      href={ticket.sourcePermalink ?? '#'}
      target="_blank"
      rel="noreferrer"
    >
      <div className="flex flex-row w-full xl:w-1/2 gap-x-2 justify-between items-center">
        <div className="flex flex-row gap-x-2 items-center">
          <img src={ticket.integration.type.logoUrl} alt={ticket.integration.type.title} className="w-3 h-3" />
          <p className="line-clamp-1">{ticket.name}</p>
        </div>
        <div className="xl:hidden">
          <TransitionMinusCircleIcon onClick={() => unlinkTicket(ticket)} />
        </div>
      </div>
      <div className="flex flex-row w-full xl:w-1/2 justify-between xl:justify-end gap-x-8">
        <div className="flex flex-row justify-between xl:grid xl:grid-cols-5 w-full xl:justify-items-end xl:justify-end italic text-sm items-center text-gray-500">
          <div className="col-span-2 flex flex-row gap-x-1 items-center">
            <p>{truncateAndEllipsis(ticket.assignee, 20)}</p>
          </div>
          <Tippy content={dueDateText}>
            <div className="col-span-2 flex flex-row gap-x-1 items-center">
              <p className="line-clamp-1 pr-1">{dueDateText}</p>
            </div>
          </Tippy>
          <Tippy content={linkedActionStatusTooltip(ticket.status, ticket.closedDate)}>
            <div className="col-span-1 flex flex-row gap-x-1 items-center">
              <StatusBadge status={ticket.status} />
            </div>
          </Tippy>
        </div>
        <div className="hidden xl:flex">
          <TransitionMinusCircleIcon onClick={() => unlinkTicket(ticket)} />
        </div>
      </div>
    </a>
  );
};

const StatusBadge = ({ status }: { status: External_Ticket_Status }) => {
  const textColor = status === External_Ticket_Status.Open ? 'text-yellow-500' : 'text-green-500';
  return (
    <div className={classNames('inline-flex items-center justify-center px-1 rounded-md border-silver-darker border-2 not-italic bg-white')}>
      <p className={classNames(textColor, 'font-semibold text-xs')}>{LinkedActionStatusMap[status]}</p>
    </div>
  );
};
const TransitionMinusCircleIcon = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      className="relative w-6 h-6 hover:cursor-pointer group"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <SolidMinusCircleIcon className="absolute text-failure inset-0 w-full h-full transition-opacity duration-200 opacity-0 group-hover:opacity-100" />
      <MinusCircleIcon className="absolute inset-0 text-failure text-opacity-50 w-full h-full transition-opacity duration-200 opacity-100 group-hover:opacity-0" />
    </div>
  );
};
