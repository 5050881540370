import { useEffect, useState } from 'react';
import { FilterInput, SeriesInput } from '../../generated/graphql';
import { IDropDownItem } from '../baseComponents/DropDown';
import { cloneDeep } from 'lodash';
import { FilterManager } from '../sections/Filters/ManagerV2/FilterManager';
import { FilterManagerDisplayMode } from '../sections/Filters/FiltersUtil';
import { VirtualizedComboBox } from './VirtualizedComboBox';
import Tippy from '@tippyjs/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { IFilter } from '../sections/Filters/FiltersTypes';

export const DataCard = ({
  teamsList,
  teamId,
  filter,
  updateFilterInputs,
  deleteFilterInput,
  dataFilters,
  setDataFilters,
  index,
}: {
  teamsList: any;
  teamId: number;
  settingsFilterManagerRef: any;
  filter: SeriesInput;
  updateFilterInputs: (filterInput: SeriesInput) => void;
  deleteFilterInput: (teamId: number) => void;
  dataFilters: SeriesInput[];
  setDataFilters: (dataFilters: SeriesInput[]) => void;
  index: number;
}) => {
  const [filterTeamId, setFilterTeamId] = useState<number>(teamId);
  const [sharedFiltersShown, setSharedFiltersShown] = useState<IFilter[]>([]);
  useEffect(() => {
    setFilterTeamId(teamId);
  }, [teamId]);
  return (
    <div className="chart-data-card w-full p-4 rounded-md border border-gray-300">
      <div className="flex flex-col gap-y-1">
        <div className="flex flex-row justify-between gap-x-3 items-center">
          <div className="w-full">
            <VirtualizedComboBox
              comboBoxData={teamsList}
              disableClear
              disableAlphabeticalSort
              grayStyle
              setSelectedItem={function (selectedItem: IDropDownItem | undefined): void {
                // so we have the filter team
                const updatedDataFilters = cloneDeep(dataFilters);
                const dataFilterIndex = dataFilters.findIndex((_, i) => i === index);
                if (dataFilterIndex > -1) {
                  updatedDataFilters[dataFilterIndex].teamIdOverride = selectedItem?.id;
                  setFilterTeamId(selectedItem?.id ?? -1);
                  setDataFilters(updatedDataFilters);
                }
              }}
              selectedItem={teamsList.find((item: any) => item.id === filterTeamId)}
            />
          </div>
          <FilterManager
            pageName={'Chart'}
            dataTypeToFilter={'chartEditor'}
            displayMode={FilterManagerDisplayMode.OnlyButton}
            filterButtonText="Filter"
            setFilterInputs={(filterInput: FilterInput) => {
              const updatedInput = cloneDeep(filter);
              updatedInput.filterInput = filterInput;
              updateFilterInputs(updatedInput);
            }}
            teamIdOverride={teamId}
            startingFilterInput={filter.filterInput ?? {}}
            overridenFiltersShown={sharedFiltersShown}
            parentSetFiltersShown={setSharedFiltersShown}
          />
          <Tippy theme="dark" content={<p>Remove current data series from chart</p>}>
            <div className="remove-chart-data-series cursor-pointer" onClick={index !== 0 ? () => deleteFilterInput(filterTeamId) : undefined}>
              <XMarkIcon className="h-5 w-5 text-gray-500" />
            </div>
          </Tippy>
        </div>
        <FilterManager
          pageName={'Chart'}
          dataTypeToFilter={'chartEditor'}
          displayMode={FilterManagerDisplayMode.OnlyFiltersShown}
          filterButtonText="Filter"
          setFilterInputs={(filterInput: FilterInput) => {
            const updatedInput = cloneDeep(filter);
            updatedInput.filterInput = filterInput;
            updateFilterInputs(updatedInput);
          }}
          teamIdOverride={teamId}
          startingFilterInput={filter.filterInput ?? {}}
          overridenFiltersShown={sharedFiltersShown}
          parentSetFiltersShown={setSharedFiltersShown}
        />
      </div>
    </div>
  );
};
