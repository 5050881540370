import moment from 'moment';
import { useEffect, useState } from 'react';

import {
  EditChartInput,
  FilterInput,
  useCreateCustomChartMutation,
  useEditChartMutation,
  useGetChartPreviewLazyQuery,
  Y_Axis_Data,
  Breakdown,
  Chart_Type,
  Chart_Bin_Type,
  useGetChart2DPreviewLazyQuery,
  Chart2DFragment,
  ChartFragment,
  useCreateWidgetsMutation,
  Board_Widget_Type,
} from '../../generated/graphql';
import toast from 'react-hot-toast';
interface CustomChartHookProps {
  teamId: number;
  orgId: number;
  userId?: number;
  chartId?: number;
  filterInput?: FilterInput;
  dateFilterInput?: FilterInput;
  pageSize?: number;
  mode?: 'editor' | 'creator';
  binType?: Chart_Bin_Type;
  initialLoadComplete?: boolean;
  boardId?: number;
}

export const useCustomChartHook = ({ teamId, filterInput, dateFilterInput, mode, binType, initialLoadComplete, boardId }: CustomChartHookProps) => {
  const [chartConfigs, setChartConfigs] = useState<EditChartInput | null>(
    mode === 'creator'
      ? {
          series: [],
          y_axis_data: Y_Axis_Data.AbsoluteVolume,
          type: Chart_Type.Line,
        }
      : null
  );
  const [currentChart, setCurrentChart] = useState<ChartFragment | undefined>();
  const [getChartPreview, chartPreview] = useGetChart2DPreviewLazyQuery();
  const [createChart, createdChart] = useCreateCustomChartMutation();
  const [editChart, editedChart] = useEditChartMutation();
  const [createWidgets, createdWidgetsRes] = useCreateWidgetsMutation();

  const createCustomChart = async (cb?: () => void) => {
    const isConfigValid = await validateRequiredFields(chartConfigs);
    if (!isConfigValid) return;

    const chartRes = await createChart({
      variables: {
        teamId,
        chartBinType: binType,
        editChartInput: { ...chartConfigs },
        filterInput: filterInput ?? { startDate: moment().subtract(90, 'days') },
      },
    });
    const createdChartId = chartRes.data?.createChart.id;
    if (boardId && createdChartId) {
      //This function could come as a param instead of specified here?
      await createWidgets({
        variables: {
          teamId,
          keepOriginalResources: true,
          inputWidgets: [
            {
              widgetType: Board_Widget_Type.ChartWidget,
              widgetTypeId: createdChartId,
            },
          ],
          boardId,
        },
        onCompleted() {
          toast('Chart added to board successfully');
        },
      });
    }
    cb?.();
  };

  const editCustomChart = async (chartId?: number, cb?: () => void) => {
    if (!chartId) return;
    const isConfigValid = await validateRequiredFields(chartConfigs);
    if (!isConfigValid) return;

    await editChart({
      variables: {
        teamId,
        chartId: chartId,
        chartBinType: binType,
        editChartInput: { ...chartConfigs },
        filterInput: filterInput ?? { startDate: moment().subtract(90, 'days') },
      },
    });
    cb?.();
  };

  const getPreview = async () => {
    if (mode === 'editor' && (!!!chartConfigs || !initialLoadComplete)) return;

    //This is a workaround.
    //What we really need to do is extract/split the filterInputs the same way we do on the get charts query.
    if (chartConfigs) {
      chartConfigs.series =
        chartConfigs.series && chartConfigs.series?.length > 0
          ? chartConfigs.series?.map((series) => {
              return { ...series, filterInput: { ...series.filterInput, startDate: dateFilterInput?.startDate, endDate: dateFilterInput?.endDate } };
            })
          : [{ filterInput: { startDate: dateFilterInput?.startDate, endDate: dateFilterInput?.endDate } }];

      await getChartPreview({
        variables: {
          teamId,
          filterInput: dateFilterInput,
          editChartInput: chartConfigs,
          chartBinType: binType,
        },
        onCompleted: (data) => {
          const chart: ChartFragment = {
            id: data.getChart2DPreview.id,
            title: data.getChart2DPreview.title,
            type: data.getChart2DPreview.config.type,
            y_axis_data: data.getChart2DPreview.config.y_axis_data,
            breakdown: data.getChart2DPreview.config.breakdown,
            seriesData: [
              {
                aggregateData: data.getChart2DPreview.series,
                normalizedData: data.getChart2DPreview.series,
                tooltipLabels: data.getChart2DPreview.tooltipLabels,
                breakdown: data.getChart2DPreview.config.breakdown,
                chartLabels: data.getChart2DPreview.xAxisLabels,
                breakdownLabels: data?.getChart2DPreview.legend,
                filterInput: data.getChart2DPreview.config.seriesConfig?.[0].filterInput ?? {},
                team: data.getChart2DPreview.config.seriesConfig?.[0].team ?? { id: -1, name: 'All Teams' },
                segmentGroupId: data.getChart2DPreview.config.seriesConfig?.[0].segmentGroupId,
              },
            ],
          };
          setCurrentChart(chart);
        },
        onError: (err) => {
          console.log(err);
        },
      });
    }
  };

  useEffect(() => {
    let isMount = true;
    const loadPreview = async () => {
      await getPreview();
    };
    if (chartConfigs?.breakdown === Breakdown.Segment && !chartConfigs.series?.[0].segmentGroupId) return;
    if (!isMount) return;
    loadPreview();
    return () => {
      isMount = false;
    };
  }, [chartConfigs, binType]);

  return {
    currentChart,
    createCustomChart,
    setCurrentChart,
    setChartConfigs,
    chartConfigs,
    editCustomChart,
    loadingStatues: {
      creatingChart: createdChart.loading || editedChart.loading,
      generatingPreview: chartPreview.loading,
    },
  };
};

const validateRequiredFields = (chartConfigs: EditChartInput | null) => {
  if (!chartConfigs) {
    toast.error('Chart configuration error - please try again');
    return false;
  }
  if (!chartConfigs.title) {
    toast.error('Title is required');
    return false;
  }
  return true;
};
