import { Fragment, useEffect } from 'react';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { Feedback_Integration_Type, useIntegrationsQuery, useListExternalTicketsQuery } from '../../../generated/graphql';
import { Popover, Transition } from '@headlessui/react';
import { useExternalTicketsDispatch } from '../../../context/externalTicketsContext';
import { ExternalTicketsActionTypes } from '../../../reducers/externalTickets/externalTicketsReducer';
import { LinkedActionsBrowsingBody } from './LinkedActionsBrowsingBody';
import Button, { ButtonVariant } from '../../baseComponents/Button';

export const LinkedActionsWidget = ({ groupId }: { groupId: number }) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const dispatch = useExternalTicketsDispatch();

  const { data: externalTicketsRes } = useListExternalTicketsQuery({
    variables: {
      groupId,
      teamId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (externalTicketsRes) {
      dispatch({ type: ExternalTicketsActionTypes.SET_EXTERNAL_TICKETS, payload: { tickets: externalTicketsRes.listExternalTickets } });
    }
  }, [externalTicketsRes]);

  return (
    <Popover className="relative items-center font-sofiapro">
      <Popover.Button data-testid="external-tickets-widget-button" className="flex flex-row focus:outline-none w-full lg:w-auto">
        <Button variant={ButtonVariant.Tertiary} expandWidth text="Link actions +" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-150"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          data-testid="external-tickets-widget-panel"
          className="absolute flex-col lg:right-0 z-50 text-blueberry bg-milk rounded-lg border border-opacity-50 shadow-lg p-1 flex w-full lg:w-[26rem]"
        >
          {({ close }) => (
            <div className="flex flex-col gap-y-2">
              <LinkedActionsBrowsingBody groupId={groupId} closeWidget={close} />
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
