import { XCircleIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/solid';
import Button, { ButtonSize, ButtonVariant } from '../baseComponents/Button';
import {
  ConnectionsDocument,
  ConnectionsQuery,
  useConnectionsQuery,
  useCreateOauthStateSecretMutation,
  useDeleteOrgConnectionMutation,
} from '../../generated/graphql';
import { ArrayElement } from '../../utilities';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import toast from 'react-hot-toast';
import UserContext from '../../v2/contexts/UserContext';
import { useContext, useState } from 'react';
import { useOrgInfoState } from '../../context/orgInfoContext';
import LoadingSpinner from '../baseComponents/LoadingSpinner';
import { isOrgUserAdmin } from '../pages/organization/util';

export default function OrgConnectionsList() {
  const { organization } = useOrgInfoState();
  const { data: connectionsRes, loading: loadingConnections } = useConnectionsQuery({ variables: { orgId: organization.id } });

  const [removeConnectionMutation, removeConQuery] = useDeleteOrgConnectionMutation();
  const handleRemoveConnection = (connectionId: number) => {
    removeConnectionMutation({
      variables: {
        connectionId: connectionId,
        orgId: organization.id,
      },
      refetchQueries: [{ query: ConnectionsDocument, variables: { orgId: organization.id } }],
      onCompleted: () => {
        toast.success('Connection removed');
      },
    });
  };
  return (
    <div className="overflow-hidden bg-white sm:rounded-md">
      {loadingConnections ? (
        <LoadingSpinner />
      ) : (
        <ul role="list" className="flex flex-col divide-y divide-gray-600">
          {connectionsRes?.connections?.map((connection) => (
            /* ADD CONFIRMATION MODAL TO THIS!! */
            <ConnectionRow key={connection.id} connection={connection} removeConnection={handleRemoveConnection} />
          ))}
        </ul>
      )}
    </div>
  );
}

const ConnectionRow = ({
  connection,
  removeConnection,
}: {
  connection: ArrayElement<ConnectionsQuery['connections']>;
  removeConnection: (connectionId: number) => void;
}) => {
  const { user } = useContext(UserContext);
  const { organization } = useOrgInfoState();
  const orgConnection = connection?.orgConnection[0];
  const [loadingOpenConnect, setLoadingOpenConnect] = useState(false);

  const [createOauthSecretMutation, createOauthData] = useCreateOauthStateSecretMutation();

  const handleConnectClick = async (connection: ArrayElement<ConnectionsQuery['connections']>) => {
    setLoadingOpenConnect(true);
    const res = await createOauthSecretMutation({
      variables: {
        orgId: organization.id,
      },
      onError(error) {
        console.log('Error authenticating:', error);
        toast.error('Failed to authenticate - please try again.');
      },
    });
    const { authUrl } = connection;
    const state = JSON.stringify({
      secret: res.data?.createOauthStateSecret?.secret_state,
      connectionTypeId: connection.id,
      destinationURL: window.location.origin + window.location.pathname,
      orgId: organization.id,
    });
    //The redirect_uri is needed when local testing. Otherwise it uses the first on the list, which is the prod Connections api.
    //const url = `${authUrl}&state=${state}&redirect_uri=https://qx7fv2t125.execute-api.us-east-2.amazonaws.com/slack/oauthd`;
    const url = `${authUrl}&state=${state}`;
    window.location.href = url;
  };
  return (
    <li key={connection.id}>
      <div className="block">
        <div className="flex items-center py-4">
          <div className="flex flex-row w-1/2 xl:w-2/3 tems-center gap-x-2">
            <div className="flex flex-row items-center gap-x-4">
              {connection.logoUrl && <img className="h-10 w-10" src={connection.logoUrl} alt="" />}
              <div className="flex flex-col w-full">
                <h1 className="text-sm xl:text-lg font-semibold text-blueberry">{connection.title}</h1>
                <p className="flex items-center text-xxs xl:text-sm text-gray-500">{connection.description}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-1/2 xl:w-1/3 text-end h-full items-end gap-y-1">
            <div className="flex items-center">
              {orgConnection ? <ConnectedIndicator workspace_name={orgConnection.connected_name} /> : <NotConnectedIndicator />}
            </div>
            {orgConnection ? (
              <Button
                variant={ButtonVariant.Tertiary}
                size={ButtonSize.Small}
                disabled={!isOrgUserAdmin({ organization, user })}
                disabledTooltip="Only Admins can unlink connections."
                text="Unlink"
                icon={<XCircleIcon className="mb-0.5 h-4 w-4" />}
                iconPosition="left"
                onClick={() => removeConnection(orgConnection.id)}
              >
                <XCircleIcon className="h-5 w-5" />
                <h1>Unlink</h1>
              </Button>
            ) : (
              <Button
                text={'Connect'}
                variant={ButtonVariant.Tertiary}
                size={ButtonSize.Small}
                icon={loadingOpenConnect ? null : <PlusIcon className="h-3 w-3 stroke-2" />}
                iconPosition="left"
                onClick={() => handleConnectClick(connection)}
                loadingConfirm={loadingOpenConnect}
                loadingText={'Connecting...'}
              />
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

const NotConnectedIndicator = () => (
  <div className="flex flex-col">
    <p className="flex items-center gap-x-1 text-sm text-gray-500">
      <span className="h-1.5 w-1.5 rounded-full bg-gray-500"></span>
      Not connected
    </p>
  </div>
);

const ConnectedIndicator = ({ workspace_name, ...props }: { workspace_name: string }) => (
  <div className="flex flex-col items-end">
    <p className="flex items-center gap-x-1 text-sm text-green-500">
      <span className="h-1.5 w-1.5 rounded-full bg-green-500"></span>
      Connected
    </p>
    <span className="text-xs text-gray-500">
      Workspace: <span className="font-semibold text-blueberry">{workspace_name}</span>
    </span>
  </div>
);
