import { useContext } from 'react';
import { GroupDataContext, GroupDispatchContext } from '../../../context/groupContext';
import Tippy from '@tippyjs/react';
import InlineEditableTextArea from '../../../baseComponents/InlineEditableTextArea';
import { GetGroupByIdDocument, useEditGroupMutation } from '../../../generated/graphql';
import { GroupActions } from '../../../reducers/group/GroupDispatch';
import { TaxonomyDispatchContext } from '../../context/TaxonomyDispatchContext';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';

interface MentionCounts {
  totalEntries: number;
  relativeShare: number | undefined;
  relativeShareFull: number | undefined;
  filteredStatsMode: boolean;
}

/**
 * This shows the Title bar on the GroupPage.
 *
 * This uses the GroupReducer to pull in the title and handle changes to the title. The mentions count comes from the GroupHook and is passed in as a prop.
 * I wanted to pull mentions out of the GroupHook but that's a bigger lift because it's tied to the ListView and PreviewPage.
 *
 * @param props
 * @returns
 */
const GroupTitleBar = (props: { mentions?: MentionCounts }) => {
  const { mentions } = props;

  return (
    <div className="flex flex-col gap-y-0 w-full ">
      <div id="group-title" className="flex-grow flex-col">
        <GroupTitleDisplay />
        <GroupMentionsDisplay mentions={mentions} />
      </div>
    </div>
  );
};

export const GroupTitleDisplay = () => {
  const { curTeamId } = useValidTeamAppContext();
  const [editGroup] = useEditGroupMutation({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });
  const { groupData, auxillaryGroupDataLoading } = useContext(GroupDataContext);
  const groupDataDispatch = useContext(GroupDispatchContext);
  const taxonomyDispatch = useContext(TaxonomyDispatchContext);

  if (auxillaryGroupDataLoading || !groupData) {
    return <TitleLoadingSkeleton />;
  }

  return (
    <InlineEditableTextArea
      ignorePadding
      textAreaId="group-title-text-area"
      value={groupData.title ?? ''}
      onEditComplete={(value) => {
        if (!groupData.id) return;
        editGroup({
          variables: { groupId: groupData.id, input: { title: value }, teamId: curTeamId, filterInput: {} },
          onCompleted: (data) => {
            if (!data.editGroup.group) return;
            // dispatch the new title to the app. There's two places the title needs to update (1) the GroupPage and (2) the backing Taxonomy Page
            groupDataDispatch({ type: GroupActions.UPDATE_GROUP_TITLE, payload: { title: data.editGroup.group.title } });
            taxonomyDispatch({ type: 'updateGroupTitle', payload: { groupId: groupData.id, title: data.editGroup.group.title } });
          },
          // this refetches the group data and silently repopulates the apollo cache
          // this makes it so next time you open the group page for this group we pull in correct data from the cache.
          // the other option is to set the fetch policy to no-cache and refetch the data each time
          refetchQueries: [{ query: GetGroupByIdDocument, variables: { groupId: groupData.id, teamId: curTeamId } }],
        });
      }}
      additionalClassNames={
        'text-2xl xl:text-3xl  h-auto font-semibold focus:border-none hover:bg-silver focus:bg-silver bg-transparent transition-[background-color] duration-100 rounded-md '
      }
    />
  );
};

export const GroupMentionsDisplay = (props: { mentions?: MentionCounts }) => {
  const { mentions } = props;
  if (!mentions) {
    return <MentionsLoadingSkeleton />;
  }
  return (
    <div className="flex flex-row text-lg gap-x-1 items-center" data-testid="mentions-title-bar">
      <p className="font-medium text-blueberry">{`${mentions.totalEntries.toLocaleString() ?? 0} mention${mentions?.totalEntries === 1 ? '' : 's'}`}</p>-
      <Tippy
        theme="dark"
        delay={200}
        content={
          <p className="text-center">
            {mentions.filteredStatsMode ? `${(mentions.relativeShare ?? 0).toFixed(1)}% of filtered feedback, ` : ''}
            {(mentions.relativeShareFull ?? 0).toFixed(1)}% of all feedback.
          </p>
        }
      >
        <p className="text-gray-500 font-medium">
          {((mentions.filteredStatsMode ? mentions.relativeShare : mentions.relativeShareFull) ?? 0).toFixed(1)}% of{' '}
          {mentions.filteredStatsMode ? 'filtered' : ''} feedback
        </p>
      </Tippy>
    </div>
  );
};

const TitleLoadingSkeleton = () => {
  return (
    <div
      data-testid="title-loading-skeleton"
      className="custom-chart-card-skeleton space-y-5 rounded-lg bg-gray-100 relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-blueberry  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-gray-100 opacity-70 h-10 w-full my-1"
    ></div>
  );
};
const MentionsLoadingSkeleton = () => {
  return (
    <div
      data-testid="mentions-loading-skeleton"
      className="custom-chart-card-skeleton space-y-5 rounded-lg bg-gray-100 relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-blueberry  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-gray-100 opacity-70 h-6 w-[8rem] my-1"
    ></div>
  );
};

export default GroupTitleBar;
