import { GroupFull, writeToastMessage } from '../../../v2/hooks/GroupHook';
import { FilterHook } from '../../hooks/FilterHook';
import { useContext, useState, useEffect } from 'react';
import { HomeGroupHook } from '../../hooks/HomeGroupHook';
import GroupPreview from '../../components/GroupPreview';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../v2/components/LoadingSpinner';
import { AppRoutes } from '../../../Routes';
import { useInView } from 'react-intersection-observer';
import AnnouncementModal from '../../components/Modals/AnnouncementModal';
import AppContext, { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { GroupDeleter } from '../../components/GroupDeleter';
import { classNames } from '../../../v2/util';
import { PageWrapper } from '../PageWrapper';
import InsightSliderController from '../../components/insights/InsightSliderController';
import { getGroupPageUrl } from '../../lib/groups';

interface HomePageProps {
  pageName: string;
  groupHook: HomeGroupHook;
  filterHook: FilterHook;
}

const HomePage = ({ pageName, groupHook, filterHook }: HomePageProps) => {
  const { orgsHaveLoaded } = useContext(AppContext);
  // user switched teams, no orgs are in url or local storage, show spinner
  if (!orgsHaveLoaded)
    return (
      <div className="flex h-[90vh] w-full flex-col items-center">
        <LoadingSpinner />
      </div>
    );
  return <HomePageWithTeam pageName={pageName} groupHook={groupHook} filterHook={filterHook} />;
};

const HomePageWithTeam = ({ pageName, groupHook, filterHook }: HomePageProps) => {
  const [hasInsightGroups, setHasInsightGroups] = useState(false);

  const { curTeamId: teamId, curOrgId: orgId, currentTeam, currentOrg } = useValidTeamAppContext();
  const navigate = useNavigate();
  const pageSize = 9;

  const { ref, inView } = useInView({ rootMargin: '100px', threshold: 0 });
  const [paginating, setPaginating] = useState(false);
  const [announcementId, setAnnouncementId] = useState<number | undefined>();
  const [curGroupIdToDelete, setCurGroupIdToDelete] = useState<number | null>(null);

  /**
   * wut is this for? If there's a tone of pinned groups?
   */
  const loadMoreGroups = async () => {
    setPaginating(true);
    await groupHook.loadMore(groupHook.groups.length, pageSize);
    setPaginating(false);
  };

  useEffect(() => {
    if (inView && !paginating) {
      // load more groups
      loadMoreGroups();
    }
  }, [inView]);
  if (!paginating && groupHook.isLoading && groupHook.groups.length === 0 && !window.location.pathname.includes('/group/')) {
    return (
      <div className="flex h-[90vh] w-full flex-col items-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <PageWrapper title="" styles={`${window.location.pathname.includes('/group/') && 'hidden'}`}>
      <>
        {announcementId != null && (
          <AnnouncementModal
            groupId={announcementId}
            modalOpen={true}
            setModalOpen={() => setAnnouncementId(undefined)}
            teamId={teamId}
            orgId={orgId}
            orgName={currentOrg?.name}
            filterInput={filterHook.filters}
          />
        )}

        {curGroupIdToDelete !== null ? (
          <GroupDeleter
            groupToDelete={curGroupIdToDelete}
            closeCallback={() => setCurGroupIdToDelete(null)}
            deleteGroup={groupHook.discardGroup}
            deleteCallback={() => {
              groupHook.setCurrentGroup(undefined);
              navigate('/dashboard/home', { replace: true });
            }}
            loadingDelete={groupHook.loadingStatuses.discardingGroup}
          />
        ) : null}
        <InsightSliderController pageName={pageName} />

        {groupHook.groups.length > 0 ? (
          <>
            <div className="pt-2">
              <h1 className="text-2xl font-semibold text-blueberry">Pinned By Me</h1>
            </div>
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {groupHook.groups.map((group) => (
                <GroupPreview
                  key={group.id}
                  isCondensedView={true}
                  group={group as GroupFull}
                  filterInput={filterHook.filters}
                  page={pageName}
                  discardSearchGroup={() => {
                    setCurGroupIdToDelete(group.id);
                  }}
                  togglePinGroup={groupHook.togglePinGroup}
                  replaceOrAddToSearchGroups={groupHook.replaceOrAddToSearchGroups}
                  getAllGroupSentences={() => groupHook.loadAllSentences(group.id)}
                  loadingAllSentences={groupHook.loadingStatuses.loadingAllSentences}
                  updateProgress={groupHook.updateProgress}
                  copyLink={(groupId) => {
                    groupHook.copyGroupLink(groupId, filterHook.filters);
                    writeToastMessage('Copied group link to clipboard');
                  }}
                  onSeeMoreClick={() => {
                    // does currentGroup need set anymore same with refetching similar sentences?
                    if (groupHook.currentGroup?.id !== group.id) {
                      groupHook.setCurrentGroup(group);
                      groupHook.refetchSimilarSentences('', 0, 10);
                    }
                    navigate(getGroupPageUrl(teamId, orgId, group.id, AppRoutes.v3FullPath.home));
                  }}
                  editTitle={groupHook.editTitle}
                  openAnnouncementModal={() => setAnnouncementId(group.id)}
                />
              ))}
            </ul>

            <div className="flex justify-center items-center" ref={ref}>
              {groupHook.loadingPins && <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-blueberry"></div>}
            </div>
          </>
        ) : !groupHook.initialLoadComplete || groupHook.isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="grid grid-cols-1">
            <div className={classNames('col-span-1 flex flex-col justify-center gap-y-5', hasInsightGroups ? 'items-start' : 'items-center')}>
              <h1 className="text-4xl font-bold text-blueberry">Welcome to Unwrap!</h1>
              <p className="font-light text-center">
                You currently have no pinned Feedback Groups.
                <br />
                Visit the Explore page to browse all insights and pin relevant Groups to your Home page.
              </p>
              <div className="flex flex-row justify-center">
                <button
                  className={
                    'hover:bg-peachring-lighter flex flex-row items-center justify-center rounded-full bg-peachring py-4 px-10 text-sm font-semibold text-milk duration-200 2xl:text-xl'
                  }
                  onClick={() => navigate(AppRoutes.v3FullPath.explore)}
                >
                  Explore Groups
                </button>
              </div>
            </div>
            <div className={classNames('col-span-1 mt-2 flex h-full max-h-min xl:mt-10', hasInsightGroups ? 'justify-start' : 'justify-center')}>
              <img src={`${window.location.origin}/HomePageCTA.jpg`} className="w-1/2" />
            </div>
          </div>
        )}
      </>
    </PageWrapper>
  );
};

export default HomePage;
