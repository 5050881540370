import { IBadge } from '../../../baseComponents/Badge';
import {
  FilterInput,
  FilterType,
  SentimentType,
  Group_Type,
  DataForFiltersQuery,
  Group_Status,
  GroupExternalTicketStatus,
  Date_Window,
  DataForFiltersQueryResult,
  SavedFilterInput,
} from '../../../generated/graphql';
import { DenominatorOptions, FilterCategory, ICategoryItem, IFilter, IFilterValue } from './FiltersTypes';
//@ts-ignore
import uuid from 'react-uuid';
import { IDropDownItem } from '../../../baseComponents/DropDown';
import sourcesMap from '../../../v2/other/sourcesMap';
import { IUser } from '../../../v2/contexts/UserContext';
import moment from 'moment';
import { HorizontalDateSelectorOption } from '../../components/HorizontalDateSelector';
import { capitalizeFirstLetter } from '../../../v2/util';

export const getFiltersWithDenominatorMethod = (filters: FilterInput, selectedDenominator: IDropDownItem | undefined): FilterInput => {
  if (!selectedDenominator) return { startDate: filters.startDate ?? undefined, endDate: filters.endDate ?? undefined };
  switch (selectedDenominator?.name) {
    case DenominatorOptions.AllFeedback:
      return {};
    case DenominatorOptions.ClusteredFeedback:
      return { clusteredOnly: true };
    case DenominatorOptions.FilteredFeedback:
      return filters;
    case DenominatorOptions.FilteredClusteredFeedback:
      return { ...filters, clusteredOnly: true };
    case DenominatorOptions.DateFilteredFeedback:
      return { startDate: filters.startDate ?? undefined, endDate: filters.endDate ?? undefined };
    default:
      return { startDate: filters.startDate ?? undefined, endDate: filters.endDate ?? undefined };
  }
};

export const getCategoryDropDown = (data?: DataForFiltersQuery, dataTypeToFilter?: FilterableDataTypes): ICategoryItem[] => {
  const items: ICategoryItem[] = [];
  let id = 0;
  if (dataTypeToFilter !== 'chart') {
    items.push({
      id: id++,
      name: 'Source',
      categoryType: FilterCategory.Source,
    });
    items.push({
      id: id++,
      name: 'Sentiment',
      categoryType: FilterCategory.Sentiment,
    });
    items.push({
      id: id++,
      name: 'Min Stars',
      categoryType: FilterCategory.MinStars,
    });
    items.push({
      id: id++,
      name: 'Max Stars',
      categoryType: FilterCategory.MaxStars,
    });
  }
  if (data?.segments && dataTypeToFilter !== 'chart') {
    items.push(
      ...data.segments?.map((segments) => {
        return {
          id: id++,
          name: `Custom Field: ${segments.displayName}`,
          realId: segments.id,
          categoryType: FilterCategory.Segment,
        };
      })
    );
  }
  //TODO: Improve this logic...it's getting ugly.
  if (dataTypeToFilter === 'group') {
    items.push({
      id: id++,
      name: 'Group Type',
      categoryType: FilterCategory.GroupType,
    });
  }
  if (dataTypeToFilter === 'group' || dataTypeToFilter === 'chart' || dataTypeToFilter === 'groupPage') {
    if (data?.getTags && data.getTags.length > 0) {
      items.push({
        id: id++,
        name: 'Tag',
        categoryType: FilterCategory.Tag,
      });
    }
  }

  if (dataTypeToFilter === 'group' || dataTypeToFilter === 'chartEditor' || dataTypeToFilter === 'entries') {
    items.push({
      id: id++,
      name: 'Group Title',
      categoryType: FilterCategory.GroupTitle,
    });
  }
  if (dataTypeToFilter === 'group' || dataTypeToFilter === 'chartEditor') {
    items.push({
      id: id++,
      name: 'Group Linked Actions',
      categoryType: FilterCategory.GroupExternalTicketsStatus,
    });
  }
  if (dataTypeToFilter === 'entries') {
    items.push({
      id: id++,
      name: 'Entry Grouped Status',
      categoryType: FilterCategory.EntryGroupedStatus,
    });
    items.push({
      id: id++,
      name: 'Source Url',
      categoryType: FilterCategory.SourceUrl,
    });
  }

  return items;
};

export const getSelectedCategoryDropDown = (selectedCategory?: ICategoryItem, data?: DataForFiltersQuery, user?: IUser | undefined): IDropDownItem[] => {
  const items: IDropDownItem[] = [];
  if (!data) return items;
  // careful here switching on enums. You have to explicitly tell compiler that this is an enum and not a number type. Otherwise all case statements are gonna get hit.
  switch (selectedCategory?.categoryType as FilterCategory) {
    case FilterCategory.Segment:
      if (!data?.segments) {
        return [];
      }

      const segment = data.segments.filter((segment) => segment.id === selectedCategory?.realId);
      if (segment.length === 0) return [];
      if (segment.length !== 1) {
        throw new Error('Found more than one custom field with id ' + selectedCategory?.realId);
      }
      let id = 0;
      return segment[0].distinctValues.map((value) => {
        return {
          name: value,
          id: id++,
        };
      });
    case FilterCategory.Sentiment:
      return [
        {
          name: SentimentType.Positive,
          displayName: 'Positive',
          id: 0,
        },
        {
          name: SentimentType.Negative,
          displayName: 'Negative',
          id: 1,
        },
        {
          name: SentimentType.Neutral,
          displayName: 'Neutral',
          id: 2,
        },
      ];
    case FilterCategory.Source:
      if (!data?.sources) {
        return [];
      }
      let newId = 0;
      return data.sources.map((source) => {
        return {
          name: source,
          id: newId++,
          displayName: sourcesMap[source]?.name ?? source,
        };
      });
    case FilterCategory.GroupType:
      return [
        {
          name: Group_Type.Search,
          displayName: 'User Created',
          id: 0,
        },
        {
          name: Group_Type.ClusterSearch,
          displayName: 'Found by Unwrap',
          id: 1,
        },
      ];
    case FilterCategory.GroupTitle:
      if (!data?.getAllGroups) return [];
      return data.getAllGroups
        .filter((group) => group.status === Group_Status.Monitored)
        .map((group) => {
          return {
            name: group.title ?? '',
            id: group.id,
          };
        });
    case FilterCategory.Tag:
      if (!data?.getTags) {
        return [];
      }
      return data.getTags.map((tag) => {
        return {
          name: tag.name,
          id: tag.id,
        };
      });
    case FilterCategory.Owner:
      if (!data?.getOrganizationUsers) {
        return [];
      }
      return data.getOrganizationUsers
        .filter((orgUser) => (!user?.isUnwrapper ? !orgUser.email.includes('@unwrap.ai') : true) && orgUser.confirmed)
        .map((orgUser) => {
          return {
            name: orgUser.email,
            id: orgUser.user?.id ?? -1,
          };
        });
    case FilterCategory.MinStars:
    case FilterCategory.MaxStars:
      return [1, 2, 3, 4, 5].map((num) => {
        return {
          name: num.toString(),
          displayName: `${num.toString()} ${'⭒'.repeat(num)}`,
          id: num,
        };
      });
    case FilterCategory.EntryGroupedStatus:
      return [
        {
          name: 'Ungrouped',
          displayName: 'Ungrouped',
          id: 0,
        },
        {
          name: 'Grouped',
          displayName: 'Grouped',
          id: 1,
        },
      ];
    case FilterCategory.SourceUrl:
      // we can't pull in entries here.
      return [];
    case FilterCategory.GroupExternalTicketsStatus:
      return [
        {
          name: GroupExternalTicketStatus.Open,
          displayName: 'Open',
          id: 0,
        },
        {
          name: GroupExternalTicketStatus.Closed,
          displayName: 'Closed',
          id: 1,
        },
        {
          name: GroupExternalTicketStatus.Unassigned,
          displayName: 'Unassigned',
          id: 2,
        },
        {
          name: GroupExternalTicketStatus.NoTickets,
          displayName: 'No Action Items',
          id: 3,
        },
      ];
    default:
      return items;
  }
};

export const getFilterValue = (selectedCategory: ICategoryItem, selectedFilter: IDropDownItem): IFilterValue => {
  switch (selectedCategory.categoryType as FilterCategory) {
    case FilterCategory.Tag:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, uiId: uuid(), name: selectedFilter?.name, id: selectedFilter?.id };
    case FilterCategory.Segment:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, segment: selectedFilter?.name, uiId: uuid() };
    case FilterCategory.Sentiment:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, sentiment: selectedFilter?.name as SentimentType, uiId: uuid() };
    case FilterCategory.Source:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, source: selectedFilter?.name, uiId: uuid() };
    case FilterCategory.GroupType:
      return {
        title: selectedFilter?.displayName ?? selectedFilter?.name,
        type: selectedFilter?.name as Group_Type,
        uiId: uuid(),
        displayName: selectedFilter?.displayName,
      };
    case FilterCategory.GroupTitle:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, id: selectedFilter?.id, uiId: uuid(), displayName: selectedFilter?.displayName };
    case FilterCategory.Owner:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, name: selectedFilter?.name, id: selectedFilter?.id, uiId: uuid() };
    case FilterCategory.MinStars:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, uiId: uuid(), id: selectedFilter?.id };
    case FilterCategory.MaxStars:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, uiId: uuid(), id: selectedFilter?.id };
    case FilterCategory.EntryGroupedStatus:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, uiId: uuid(), id: selectedFilter?.id };
    case FilterCategory.SourceUrl:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, uiId: uuid(), id: selectedFilter?.id };
    case FilterCategory.GroupExternalTicketsStatus:
      return {
        title: selectedFilter?.displayName ?? selectedFilter?.name,
        uiId: uuid(),
        id: selectedFilter?.id,
        externalTicketStatus: selectedFilter?.name as GroupExternalTicketStatus,
      };
    default:
      throw new Error('Could not find filter category ' + selectedCategory?.categoryType);
  }
};
export const addFilter = (selectedCategory: ICategoryItem, selectedFilter: IDropDownItem, setFilter: (filter: IFilter) => void, filter?: IFilter) => {
  if (filter) {
    if (filter.filterCategory !== selectedCategory.categoryType) {
      throw new Error("You can't add a filter from one category into another.");
    }
    const newFilter = { ...filter };
    const value = getFilterValue(selectedCategory, selectedFilter);
    const existing = newFilter.values.find((currVal) => {
      return currVal.title === value.title;
    });
    if (!existing) {
      newFilter.values.push(value);
    }

    setFilter(newFilter);
  } else {
    const filter: IFilter = {
      filterCategory: selectedCategory.categoryType,
      // set the category id here. This is how we're saving the feedbackSegmentGroupId
      // could show an error here if this value is null?
      filterCategoryId: selectedCategory.realId,
      filterCategoryTitle: selectedCategory.name,
      filterCondition: FilterType.And,
      uiId: uuid(),
      values: [getFilterValue(selectedCategory, selectedFilter)],
    };
    setFilter(filter);
  }
};
export const removeFilter = (item: IBadge, setFilter: (filter: IFilter) => void, filter: IFilter): void => {
  const index = filter.values.findIndex((value) => {
    return value.uiId === item.id;
  });
  filter.values.splice(index, 1);
  setFilter({ ...filter });
};

export const getPlaceholderText = (selectedCategory: ICategoryItem): string => {
  return `Enter ${selectedCategory?.name.replace('Custom Field: ', '')}`;
};

export type FilterableDataTypes = 'group' | 'entries' | 'chart' | 'chartEditor' | 'groupPage';

export enum FilterManagerDisplayMode {
  Regular,
  ChartsPage, //Only search and date
  ChartEditor, //Only filters
  ChartEditorPreview, //Only date
  GroupModal, //Only date
  OnlyButton,
  OnlyFiltersShown,
  GroupPage,
  GroupPageNoFiltersShown,
  GroupPageOnlyFiltersShown,
  FeedbackPage,
  ExplorePage,
}

type ComponentType = 'search' | 'dates' | 'filterButton' | 'filtersShown' | 'exportToCSV';

export function shouldShowFilterComponents(component: ComponentType, currentMode: FilterManagerDisplayMode): boolean {
  const visibilityMap: Record<FilterManagerDisplayMode, ComponentType[]> = {
    [FilterManagerDisplayMode.Regular]: ['search', 'dates', 'filterButton', 'filtersShown'],
    [FilterManagerDisplayMode.ChartsPage]: ['dates', 'filterButton', 'filtersShown'],
    [FilterManagerDisplayMode.ChartEditor]: ['filterButton', 'filtersShown'],
    [FilterManagerDisplayMode.ChartEditorPreview]: ['dates'],
    [FilterManagerDisplayMode.GroupModal]: ['dates'],
    [FilterManagerDisplayMode.OnlyButton]: ['filterButton'],
    [FilterManagerDisplayMode.OnlyFiltersShown]: ['filtersShown'],
    [FilterManagerDisplayMode.GroupPage]: ['dates', 'filterButton', 'filtersShown'],
    [FilterManagerDisplayMode.GroupPageNoFiltersShown]: ['dates', 'filterButton'],
    [FilterManagerDisplayMode.GroupPageOnlyFiltersShown]: ['filtersShown'],
    [FilterManagerDisplayMode.FeedbackPage]: ['search', 'dates', 'filterButton', 'filtersShown', 'exportToCSV'],
    [FilterManagerDisplayMode.ExplorePage]: ['dates', 'filterButton', 'filtersShown'],
  };

  return visibilityMap[currentMode].includes(component);
}

const endOfToday = moment().endOf('day').toDate();
export const DateOptions: HorizontalDateSelectorOption[] = [
  { name: '7D', startDate: moment().subtract(7, 'days').startOf('day').toDate(), endDate: endOfToday, date_window: Date_Window.Last_7d },
  { name: '30D', startDate: moment().subtract(30, 'days').startOf('day').toDate(), endDate: endOfToday, date_window: Date_Window.Last_30d },
  { name: '90D', startDate: moment().subtract(90, 'days').startOf('day').toDate(), endDate: endOfToday, date_window: Date_Window.Last_90d },
  { name: '1Y', startDate: moment().subtract(1, 'year').startOf('day').toDate(), endDate: endOfToday, date_window: Date_Window.Last_1y },
  { name: 'All', startDate: moment().startOf('day').toDate(), endDate: endOfToday, disabled: true, date_window: Date_Window.All },
];

export const getBadgeText = (filter: IFilter, hideTeamName?: boolean): string => {
  const properCase = (text: string) => (['NEGATIVE', 'NEUTRAL', 'POSITIVE'].includes(text) ? capitalizeFirstLetter(text) : text);
  return (
    (!hideTeamName && filter.teamName != null ? filter.teamName + ': ' : '') +
    '' +
    filter.filterCategoryTitle +
    ':' +
    filter.values
      .map((value, index) =>
        index === 0 ? ' ' + properCase(value.displayName ?? value.title) : ' ' + filter.filterCondition + ' ' + properCase(value.displayName ?? value.title)
      )
      .join('')
  );
};

export const setUiFilters = async (
  filterInput: FilterInput,
  setFiltersShown: (filters: IFilter[]) => void,
  getDataForFilters: () => Promise<DataForFiltersQueryResult>
) => {
  const filters: IFilter[] = [];
  const { data } = await getDataForFilters();

  filterInput.segmentFilters?.map((segment) => {
    filters.push({
      filterCategory: FilterCategory.Segment,
      filterCategoryTitle: 'Custom Field',
      filterCategoryId: segment.groupId,
      uiId: uuid(),
      filterCondition: segment.filterCondition,
      values: segment.segments.map((seg) => {
        return { segment: seg, uiId: uuid(), title: seg };
      }),
    });
  });

  filterInput.sentimentFilter?.map((sentiment) => {
    filters.push({
      filterCategory: FilterCategory.Sentiment,
      filterCategoryTitle: 'Sentiment',
      uiId: uuid(),
      filterCondition: sentiment.filterCondition,
      values: sentiment.sentiments.map((sentiment) => {
        return { uiId: uuid(), title: sentiment, sentiment: sentiment };
      }),
    });
  });

  filterInput.sourceFitler?.map((source) => {
    filters.push({
      filterCategory: FilterCategory.Source,
      filterCategoryTitle: 'Source',
      uiId: uuid(),
      filterCondition: source.filterCondition,
      values: source.sources.map((sourceInd) => {
        return { uiId: uuid(), title: sourcesMap[sourceInd]?.name ?? sourceInd, source: sourceInd };
      }),
    });
  });
  filterInput.tagFilters?.map((tag) => {
    filters.push({
      filterCategory: FilterCategory.Tag,
      filterCategoryTitle: 'Tag',
      uiId: uuid(),
      filterCondition: tag.filterCondition,
      values: tag.tags.map((tagInd) => {
        return { uiId: uuid(), title: data?.getTags?.find((t) => t.id === tagInd.id)!.name ?? '', id: tagInd.id };
      }),
    });
  });
  filterInput.clusterFilters?.map((clusterFilter) => {
    filters.push({
      filterCategory: FilterCategory.GroupTitle,
      filterCategoryTitle: 'Group Title',
      uiId: uuid(),
      filterCondition: clusterFilter.filterCondition,
      values: clusterFilter.clusters.map((clusterFilterInd) => {
        return {
          id: clusterFilterInd.id ?? -1,
          title: clusterFilterInd.title,
          uiId: uuid(),
        };
      }),
    });
  });
  filterInput.ownerFilter?.map((ownerFilter) => {
    filters.push({
      filterCategory: FilterCategory.Owner,
      filterCategoryTitle: 'Owner',
      uiId: uuid(),
      filterCondition: ownerFilter.filterCondition,
      values: ownerFilter.owner.map((ownerFilterInd) => {
        return {
          id: ownerFilterInd.user_id ?? -1,
          title: data?.getOrganizationUsers?.find((u) => u.user?.id === ownerFilterInd.user_id)?.email ?? '',
          uiId: uuid(),
        };
      }),
    });
  });
  filterInput.groupFilter?.map((groupFilter) => {
    if (groupFilter.group[0].id) {
      // if groupFilter has an id, then add a group title filter
      filters.push({
        filterCategory: FilterCategory.GroupTitle,
        filterCategoryTitle: 'Group Title',
        uiId: uuid(),
        filterCondition: groupFilter.filterCondition,
        values: groupFilter.group.map((groupFilterInd) => {
          return {
            id: groupFilterInd.id ?? -1,
            title: data?.getAllGroups?.find((g: any) => g.id === groupFilterInd.id)?.title ?? '',
            uiId: uuid(),
          };
        }),
      });
    } else {
      // else add a group type filter
      filters.push({
        filterCategory: FilterCategory.GroupType,
        filterCategoryTitle: 'Group Type',
        uiId: uuid(),
        filterCondition: groupFilter.filterCondition,
        values: groupFilter.group.map((groupFilterInd) => {
          return {
            uiId: uuid(),
            title: groupFilterInd.type ?? '',
            group: groupFilterInd.type,
            //I dont like this conditional here, but we have similar filter logic repeated in different places.
            //We should take care of that first to fix stuff like this.
            displayName: groupFilterInd.type === 'search' ? 'User Created' : 'Found by Unwrap',
          };
        }),
      });
    }
  });
  filterInput.minStarsFilter?.map((minStarsFilter) => {
    filters.push({
      filterCategory: FilterCategory.MinStars,
      filterCategoryTitle: 'Min Stars',
      uiId: uuid(),
      filterCondition: minStarsFilter.filterCondition,
      values: minStarsFilter.amounts.map((amount) => {
        return {
          id: amount,
          title: amount.toString(),
          uiId: uuid(),
          displayName: `${amount.toString()} ${'⭒'.repeat(amount)}`,
        };
      }),
    });
  });
  filterInput.maxStarsFilter?.map((maxStarsFilter) => {
    filters.push({
      filterCategory: FilterCategory.MaxStars,
      filterCategoryTitle: 'Max Stars',
      uiId: uuid(),
      filterCondition: maxStarsFilter.filterCondition,
      values: maxStarsFilter.amounts.map((amount) => {
        return {
          id: amount,
          title: amount.toString(),
          uiId: uuid(),
          displayName: `${amount.toString()} ${'⭒'.repeat(amount)}`,
        };
      }),
    });
  });
  filterInput.entryGroupedStatusFilter?.map((entryGroupedStatusFilter) => {
    filters.push({
      filterCategory: FilterCategory.EntryGroupedStatus,
      filterCategoryTitle: 'Entry Grouped Status',
      uiId: uuid(),
      filterCondition: entryGroupedStatusFilter.filterCondition,
      values: entryGroupedStatusFilter.grouped.map((groupedBool) => {
        return {
          title: groupedBool ? 'Grouped' : 'Ungrouped',
          uiId: uuid(),
          displayName: groupedBool ? 'Grouped' : 'Ungrouped',
        };
      }),
    });
  });
  filterInput.sourceUrlFilter?.map((sourceUrlFilter) => {
    filters.push({
      filterCategory: FilterCategory.SourceUrl,
      filterCategoryTitle: 'Source Url',
      uiId: uuid(),
      filterCondition: sourceUrlFilter.filterCondition,
      values: sourceUrlFilter.urls.map((url) => {
        return {
          title: url,
          uiId: uuid(),
        };
      }),
    });
  });
  filterInput.groupExternalTicketsFilter?.map((groupExternalTicketsFilter) => {
    filters.push({
      filterCategory: FilterCategory.GroupExternalTicketsStatus,
      filterCategoryTitle: 'Group Linked Actions',
      uiId: uuid(),
      filterCondition: groupExternalTicketsFilter.filterCondition,
      values: groupExternalTicketsFilter.status.map((status) => {
        return {
          title: status,
          uiId: uuid(),
          externalTicketStatus: status,
        };
      }),
    });
  });
  setFiltersShown(filters);
};