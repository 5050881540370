import { FilterHook } from '../../hooks/FilterHook';
import { GROUP_ENTRIES_PAGE_SIZE } from '../../../v2/hooks/GroupHook';
import { useEffect, useReducer, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import FeedbackEntryCard from '../FeedbackEntryCard';
import SearchInput from '../../baseComponents/SearchInput';
import { SmallSpinner } from '../SmallSpinner';
import { GroupEntriesActions, GroupEntriesInitialState, GroupEntriesReducer } from '../../../reducers/group/GroupEntriesReducer';
import {
  EntryFragment,
  FeedbackEntriesQuery,
  FilterType,
  Group_Membership_Action,
  useFeedbackEntriesLazyQuery,
  useFeedbackSentencesLazyQuery,
  useModifyEntryGroupMembershipMutation,
} from '../../../generated/graphql';
import toast from 'react-hot-toast';
import { logError } from '../../../applicationTelemetry';
import { groupBy } from 'lodash';

interface GroupEntriesProps {
  filterHook: FilterHook;
  teamId: number;
  groupId: number;
}

/**
 *
 * Manages the entries and similar entries completely isolated from anything else on the GroupPage.
 *
 * Now one caveat if you add / remove entries from a group the mentions count wont necessarily update.
 * - let's see if anyone notices calls this out as a problem.
 * - We'll fix this by passing in a method that will either
 *    1. increment / decrement the value on the title bar
 *    2. force refetch the data for the title bar
 *
 */
export const GroupEntries = ({ filterHook, teamId, groupId }: GroupEntriesProps) => {
  const [groupEntries, dispatchGroupEntries] = useReducer(GroupEntriesReducer, GroupEntriesInitialState());
  const [modifyMembership] = useModifyEntryGroupMembershipMutation();

  const [sentencesQuery] = useFeedbackSentencesLazyQuery({});
  /**
   * Be careful here: fetchPolicy: 'no-cache' is used to ensure that when we pagniate we get the next page of data.
   *
   * We use the cache.tsx to define how entires are cached. We've (likely) made a hack that bastardizes the cache to store paginated data.
   * This is used on the FeedbackPage (through the FeedbackHook) to store paginated data.
   * The FeedbackPage should manage all of it's own data and not rely on the cache to store paginated data, and not rely on the FeedbackHook to handle it's data fetching.
   *
   * Anywho, TLDR; we're using 'no-cache' to ensure we get the next page of data.
   */
  const [getFeedbackEntries] = useFeedbackEntriesLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [paginating, setPaginating] = useState(false);

  const [addSentencesQuery, setAddSentencesQuery] = useState<string>('');
  const { ref: scrollRef, inView } = useInView({
    rootMargin: '300px',
    threshold: 0,
  });

  const [currentTabId, setCurrentTabId] = useState<number>(0);
  const tabs = [
    {
      id: 0,
      name: 'Mentions',
    },
    { id: 1, name: 'Add Sentences +' },
  ];

  useEffect(() => {
    /**
     * Why we gotta do this switcheroo based on currentTabId in so many different places?
     */
    if (currentTabId == 0 && groupEntries.endOfEntriesReached) return;
    if (currentTabId == 1 && groupEntries.endOfSimilarEntriesReached) return;
    if (inView) {
      // this 'setPaginating' smells bad...
      setPaginating(true);
      if (currentTabId === 0) {
        // we want to load / paginate on entries
        fetchEntries({
          skipAmount: groupEntries.entriesSkipAmount,
          onQueryCompleted: (data) => {
            dispatchGroupEntries({ type: GroupEntriesActions.APPEND_ENTRIES, payload: { entries: data.entries } });
            setPaginating(false);
          },
        });
      } else if (currentTabId === 1) {
        // we want to load in / paginate on similarEntries
        fetchSimilarSentences({
          searchTerm: addSentencesQuery,
          skipAmount: groupEntries.similarEntriesSkipAmount,
          onQueryCompleted: (data) => {
            dispatchGroupEntries({ type: GroupEntriesActions.APPEND_SIMILAR_ENTRIES, payload: { entries: data } });
            setPaginating(false);
          },
        });
      }
    }
  }, [inView, currentTabId]);

  useEffect(() => {
    // set initial loading states
    // hmmm this is pretty smelly. I bet there's two components that can come out of this...
    dispatchGroupEntries({ type: GroupEntriesActions.SET_ENTRIES_LOADING, payload: {} });
    dispatchGroupEntries({ type: GroupEntriesActions.SET_SIMILAR_ENTRIES_LOADING, payload: {} });
    fetchEntries({
      skipAmount: 0,
      onQueryCompleted: (data) => {
        dispatchGroupEntries({ type: GroupEntriesActions.SET_ENTRIES, payload: { entries: data.entries } });
      },
    });
    fetchSimilarSentences({
      skipAmount: 0,
      searchTerm: undefined,
      onQueryCompleted: (data) => dispatchGroupEntries({ type: GroupEntriesActions.SET_SIMILAR_ENTRIES, payload: { entries: data } }),
    });
    // load entries and similar entries
    // set entries and similar entries states
  }, [filterHook.filters, teamId, groupId]);

  /**
   * This is called from the FeedbackEntryCard to delete the entry from the group
   * @param entryId
   */
  const deleteEntry = async (entryId: number) => {
    await modifyMembership({
      variables: {
        groupId,
        entryId,
        teamId,
        action: Group_Membership_Action.Remove,
      },
      onCompleted: (data) => {
        dispatchGroupEntries({ type: GroupEntriesActions.REMOVE_ENTRY, payload: { entryId } });
        toast.success('Entry removed from Group');
      },
      onError: (err) => {
        logError(err);
        toast.error('Error: Unable to remove the Entry from the Group.');
      },
    });
  };

  /**
   * This is called from the FeedbackEntryCard to add the entry to the group
   * @param entryId
   */
  const addEntry = async (entryId: number) => {
    await modifyMembership({
      variables: {
        groupId,
        entryId,
        teamId,
        action: Group_Membership_Action.Add,
      },
      onCompleted: (data) => {
        dispatchGroupEntries({ type: GroupEntriesActions.ADD_ENTRY, payload: { entryId } });
        toast.success('Entry added to Group');
      },
      onError: (err) => {
        logError(err);
        toast.error('Error: Unable to add the Entry to the Group.');
      },
    });
  };

  /**
   * This should get called on:
   * 1. initial page load
   * 2. on pagination (when a user scrolls down)
   * @param skipAmount
   * @param onQueryCompleted
   */
  const fetchEntries = async (params: { skipAmount: number; onQueryCompleted: (data: FeedbackEntriesQuery) => void }) => {
    await getFeedbackEntries({
      variables: {
        teamId,
        take: GROUP_ENTRIES_PAGE_SIZE,
        skip: params.skipAmount,
        filterInput: {
          ...filterHook.filters,
          // filter to our specific group
          // :shame: I'm apollogise from the bottom of my heart about this fucking filter contract.
          groupFilter: [
            {
              filterCondition: FilterType.And,
              group: [{ id: groupId }],
            },
            ...(filterHook.filters.groupFilter ?? []),
          ],
        },
      },
      onCompleted: (data) => {
        params.onQueryCompleted(data);
      },
    });
  };

  /**
   * Called when:
   * 1. a user puts a query in the similar sentences search bar
   * 2. initial toggle to the 'Add Sentences' tab
   */
  const fetchSimilarSentences = async (params: { searchTerm: string | undefined; skipAmount: number; onQueryCompleted: (data: EntryFragment[]) => void }) => {
    await sentencesQuery({
      variables: {
        teamId,
        sortByClusterId: groupId,
        take: GROUP_ENTRIES_PAGE_SIZE,
        skip: params.skipAmount,
        filterInput: { queryString: params.searchTerm ? [params.searchTerm] : [] },
      },
      onCompleted: (data) => {
        params.onQueryCompleted(data.sentences?.map((sentence) => sentence.entry) ?? []);
      },
    });
  };

  // can we just explicitly check the loading state? - let's see?
  if (groupEntries.entriesLoading)
    return (
      <div className="flex flex-col gap-y-4 min-h-screen w-full bg-milk mt-4 px-8">
        {/* Show three itmes below of the loading skeleton */}
        <EntriesLoadingSkeleton />
        <EntriesLoadingSkeleton />
        <EntriesLoadingSkeleton />
      </div>
    );
  return (
    <div className="flex flex-col gap-y-2 min-h-screen bg-milk mt-4 mx-8">
      <div className="flex flex-row gap-x-4 items-baseline">
        {tabs.map((tab) => {
          return (
            <p
              key={tab.id}
              id={tab.name.replace(' ', '')}
              className={`${
                currentTabId === tab.id ? 'font-semibold text-blueberry decoration-2 underline' : ' text-gray-500 hover:underline'
              } cursor-pointer text-lg underline-offset-4`}
              onClick={() => setCurrentTabId(tab.id)}
            >
              {tab.name}
            </p>
          );
        })}
      </div>

      {currentTabId === 0
        ? groupEntries.entries.length > 0
          ? groupEntries.entries.map((entry) => {
              // how do we handle deletes or adds?
              // can pass in the dispatch
              return (
                <FeedbackEntryCard
                  key={entry.id}
                  entry={entry}
                  compactAvailable={true}
                  compact={true}
                  deleteEntryFromParentComponentContext={(entryId) => deleteEntry(entryId)}
                />
              );
            })
          : null
        : null}
      {currentTabId === 1 && (
        <div className="pt-2 relative">
          <div className="px-1 mb-3">
            <SearchInput
              queryString={addSentencesQuery}
              placeholder={'Add more sentences'}
              setQueryString={(string) => setAddSentencesQuery(string ?? '')}
              onSearch={async (q) => {
                // you may need to explicity clear the state here by setting a loading and clear
                dispatchGroupEntries({ type: GroupEntriesActions.SET_SIMILAR_ENTRIES_LOADING, payload: {} });
                // why are there two interrupts used here? why not just use one?
                fetchSimilarSentences({
                  searchTerm: q,
                  skipAmount: 0,
                  onQueryCompleted: (data) => dispatchGroupEntries({ type: GroupEntriesActions.SET_SIMILAR_ENTRIES, payload: { entries: data } }),
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-y-2">
            {!groupEntries.similarEntriesLoading && groupEntries.similarEntries.length === 0 ? (
              <p className="pt-4">No similar sentences were found.</p>
            ) : (
              groupEntries.similarEntries &&
              groupEntries.similarEntries.map((entry) => {
                return (
                  <FeedbackEntryCard
                    key={`similar-${entry.id}`}
                    entry={entry}
                    compactAvailable={true}
                    compact={true}
                    addEntryToParentComponentContext={(entryId) => addEntry(entryId)}
                  />
                );
              })
            )}
          </div>
        </div>
      )}
      {paginating ? (
        <div className="flex flex-row items-baseline gap-x-2">
          <SmallSpinner />
          Loading more entries...
        </div>
      ) : null}
      {groupEntries.entries ? <div ref={scrollRef} className="mb-4"></div> : null}
    </div>
  );
};

const EntriesLoadingSkeleton = () => {
  return (
    <div
      className="custom-chart-card-skeleton space-y-5 rounded-lg bg-gray-100 relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-blueberry before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-gray-100 opacity-70 h-[8rem]"
    >
      <div className="space-y-3 p-4">
        <div className="h-4 bg-gray-300 rounded w-3/4"></div>
        <div className="h-4 bg-gray-300 rounded w-1/2"></div>
        <div className="h-4 bg-gray-300 rounded w-5/6"></div>
        <div className="h-4 bg-gray-300 rounded w-1/3"></div>
      </div>
    </div>
  );
};
