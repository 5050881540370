import { useEffect, useState } from 'react';
import { FilterInput, IChartableItem } from '../../generated/graphql';
import AnnotationsSettingsMenu from '../../v2/sections/Charts/Modals/AnnotationSettingsMenu';
import { IChartableData, ChartType, useChartHook } from '../../v2/hooks/ChartHook';
import ChartLoading from '../../v2/sections/Charts/ChartLoading';
import ChartView from '../../baseComponents/ChartView';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { IChartableSeries } from '../../baseComponents/VerticalStackedBarChart';
import { areFiltersEmpty, classNames } from '../../v2/util';
interface ClusterSparkChartProps {
  filterInput?: FilterInput;
  artificialStartDate?: Date;
  disableAnnotations?: boolean;
  setPercentChangeOfLastTwoDataPoints?: (direction: number) => void; // the direction is indicated,
  aggregateData?: number[];
  normalizedData?: number[];
  tooltipLabels?: string[];
  chartLabels?: string[];
  autoHeight?: boolean;
}

const ClusterSparkChart = ({
  filterInput,
  artificialStartDate,
  disableAnnotations,
  setPercentChangeOfLastTwoDataPoints,
  aggregateData,
  normalizedData,
  tooltipLabels,
  chartLabels,
  autoHeight,
}: ClusterSparkChartProps) => {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const [settingsMenuOpen, setSettingsMenuOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const {
    loading,
    chartData,
    chartOptions,
    currentAnnotation,
    annotations,
    menuCoords,
    range,
    setCurrentAnnotation,
    createAnnotation,
    deleteAnnotation,
    editAnnotation,
  } = useChartHook(
    teamId,
    {
      data: [] as IChartableSeries[],
      startDate: artificialStartDate ?? filterInput?.startDate!,
      endDate: filterInput?.endDate!,
      denominator: {
        label: 'denom',
        dataSeries: [] as IChartableItem[],
      },
    } as IChartableData,
    ChartType.Mixed,
    setSettingsMenuOpen,
    disableAnnotations,
    aggregateData,
    normalizedData,
    tooltipLabels,
    chartLabels,
    !areFiltersEmpty(filterInput ?? {})
  );

  useEffect(() => {
    /**
     * This takes the chart that's going to be shown to the user and calculates the percent change based on the last two data points in the chart.
     *
     * Had a chat with Ashwin about this. We figured this was the most defensible solution, we can explain it to customers and when you look at the chart
     * it makes sense seeing growing and shrinking indicated.
     */
    if (setPercentChangeOfLastTwoDataPoints && chartData.datasets.length > 0) {
      const data = chartData.datasets[0].data;
      if (data.length > 2) {
        const [secondToLast, last] = data.slice(data.length - 2);
        if (typeof last === 'number' && typeof secondToLast === 'number') {
          if (last === 0) {
            setPercentChangeOfLastTwoDataPoints(-1);
          } else if (secondToLast === 0) {
            setPercentChangeOfLastTwoDataPoints(1);
          } else {
            setPercentChangeOfLastTwoDataPoints((last - secondToLast) / secondToLast);
          }
        }

        // data.data;
      }
    }
  }, [chartData]);

  return (
    <div data-testid="chart" className={classNames(autoHeight && 'h-full')}>
      {!loading && chartData && chartOptions ? (
        <div className={classNames(`relative pt-2`, autoHeight ? 'h-full' : 'h-[12.25rem]')}>
          <ChartView type="line" data={chartData} options={chartOptions} />
          {!disableAnnotations && settingsMenuOpen && (
            <AnnotationsSettingsMenu
              x={menuCoords.x}
              y={menuCoords.y}
              value={menuCoords.value}
              range={range}
              addAnnotation={createAnnotation}
              addModalOpen={addModalOpen}
              setAddModalOpen={setAddModalOpen}
              setEditModalOpen={setEditModalOpen}
              setDeleteModalOpen={setDeleteModalOpen}
              setSettingsMenuOpen={setSettingsMenuOpen}
              editModalOpen={editModalOpen}
              deleteModalOpen={deleteModalOpen}
              editAnnotation={editAnnotation}
              deleteAnnotation={deleteAnnotation}
              annotations={annotations}
              currentAnnotation={currentAnnotation}
              setCurrentAnnotation={setCurrentAnnotation}
            />
          )}
        </div>
      ) : (
        <ChartLoading />
      )}
    </div>
  );
};

export default ClusterSparkChart;
